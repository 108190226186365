import Vue from "vue";
import { mdiDotsVertical } from "@mdi/js";
import THEME from "../../../../constants/theme";
import SingleMenuItemComponent from "app/ui-primitives/menus/popup-menu/SingleMenuItem.vue";
import MultiMenuItemComponent from "app/ui-primitives/menus/popup-menu/MultiMenuItem.vue";
export default Vue.extend({
    name: "PopupMenu",
    components: {
        MultiMenuItem: MultiMenuItemComponent,
        SingleMenuItem: SingleMenuItemComponent,
    },
    props: { items: { type: Array, required: true } },
    setup: function (props, context) {
        var _a = THEME.typography, fontFamily = _a.fontFamily, fontWeightRegular = _a.fontWeightRegular, rootFontSize = _a.rootFontSize;
        var textColor = THEME.colors.light.textColor;
        return {
            menuIcon: mdiDotsVertical,
            listItemStyles: {
                fontFamily: fontFamily,
                fontSize: "".concat(0.9 * rootFontSize, "px"),
                fontWeight: fontWeightRegular,
                color: textColor,
            },
        };
    },
});
