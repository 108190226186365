import { __awaiter, __generator } from "tslib";
import { inject, provide, ref } from "vue";
import { RsvpReply } from "suus-api";
import { rsvpApi } from "store/api";
import "app/modules/events/use-own-user-attendance-context.concern";
import { Attends, } from "app/modules/events/rsvp-context/attends.concern";
import { Declines, } from "app/modules/events/rsvp-context/declines.concern";
var RSVP_LOAD_LIMIT = 12;
function RSVPConcern(eventId, loadRSVPCounts, ownUserAttendanceContext) {
    var _this = this;
    var attendsConcern = Attends(eventId, ownUserAttendanceContext.ownUserId, ownUserAttendanceContext.ownUserAttends, RSVP_LOAD_LIMIT);
    var declinesConcern = Declines(eventId, RSVP_LOAD_LIMIT);
    /**
     * Should be called once, when the event is loaded.
     * @param initialAttendsCount
     * @param initialGuestsCount
     * @param initialDeclinesCount
     */
    var initRSVPConcern = function (initialAttendsCount, initialGuestsCount, initialDeclinesCount) {
        attendsConcern.updateCounts({
            attendsCount: initialAttendsCount,
            guestsCount: initialGuestsCount,
        });
        declinesConcern.updateDeclinesCount(initialDeclinesCount);
    };
    var loading = ref(false);
    var error = ref(null);
    var rsvpsInitialized = ref(false);
    var preloadRSVPs = function () {
        if (!rsvpsInitialized.value) {
            // rsvpsInitialized is set inside _reloadRSVPs, on success
            _reloadRSVPs();
        }
    };
    // Can be used for initial loading of the RSVPS too.
    var _reloadRSVPs = function () { return __awaiter(_this, void 0, void 0, function () {
        var _error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    error.value = null;
                    loading.value = true;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, attendsConcern.loadFirstAttends()];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, declinesConcern.loadFirstDeclines()
                        // Set Initialised Flag
                    ];
                case 3:
                    _a.sent();
                    // Set Initialised Flag
                    rsvpsInitialized.value = true;
                    return [3 /*break*/, 6];
                case 4:
                    _error_1 = _a.sent();
                    if (_error_1 instanceof Error) {
                        error.value = _error_1.message;
                    }
                    else {
                        error.value = "An error occurred while fetching RSVPs.";
                    }
                    console.error("An error occurred while fetching RSVPs.", _error_1);
                    return [3 /*break*/, 6];
                case 5:
                    loading.value = false;
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var _refreshCounts = function () { return __awaiter(_this, void 0, void 0, function () {
        var counts, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, loadRSVPCounts()];
                case 1:
                    counts = _a.sent();
                    attendsConcern.updateCounts({
                        attendsCount: counts.attendsCount,
                        guestsCount: counts.guestsCount,
                    });
                    declinesConcern.updateDeclinesCount(counts.declinesCount);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("An error occurred while refreshing counts in RSVP Concern.", error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // The Attends and Declines counts have to be refreshed before reloading the rsvps. (_refreshCounts)
    // Else the "All Loaded" flags will be incorrect.
    var reloadRSVPsAfterUpdate = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, _refreshCounts()];
                case 1:
                    _a.sent();
                    return [2 /*return*/, _reloadRSVPs()];
            }
        });
    }); };
    var createRSVP = function (personalRecordId, reply, guestsCount) {
        if (guestsCount === void 0) { guestsCount = 0; }
        rsvpApi
            .createRsvp(eventId, personalRecordId.toString(), {
            reply: reply,
            guests_count: guestsCount,
        })
            .then(function (response) {
            if (reply === RsvpReply.Attend) {
                attendsConcern.incrementExpectedAttendsCount();
                attendsConcern.addAttends([response.data]);
            }
            else if (reply === RsvpReply.Decline) {
                declinesConcern.incrementExpectedDeclinesCount();
                declinesConcern.addDeclines([response.data]);
            }
            else {
                console.error("Other Reply than Attend or Decline are not supported yet.");
            }
        })
            .catch(function (error) {
            console.log(error);
        });
    };
    var updateRSVP = function (personalRecordId, reply, guestsCount) {
        if (guestsCount === void 0) { guestsCount = 0; }
        rsvpApi
            .updateRsvp(eventId, personalRecordId.toString(), {
            reply: reply,
            guests_count: guestsCount,
        })
            .then(function (_) {
            reloadRSVPsAfterUpdate();
        })
            .catch(function (error) {
            console.log(error);
        });
    };
    return {
        attends: attendsConcern,
        declines: declinesConcern,
        initRSVPConcern: initRSVPConcern,
        loading: loading,
        error: error,
        preloadRSVPs: preloadRSVPs,
        reloadRSVPs: reloadRSVPsAfterUpdate,
        createRSVP: createRSVP,
        updateRSVP: updateRSVP,
    };
}
/**
 * This should be called in the top-level component, where the Context should be set up.
 * This probably shouldn't be called multiple times, as it will create multiple contexts.
 */
export function provideRSVPContext(eventId, loadRSVPCounts, ownUserAttendanceContext) {
    var concern = RSVPConcern(eventId, loadRSVPCounts, ownUserAttendanceContext);
    provide("RSVPConcern", concern);
    return concern;
}
/**
 * This should be called in any component that needs to access the RSVP context.
 * It expects that the context has been set up by `provideRSVPContext`.
 */
export function useRSVPContext() {
    var concern = inject("RSVPConcern", null);
    if (concern === null) {
        throw new Error("RSVPConcern was not found in 'useRSVPContext'. Did you forget to provide a context with 'provideRSVPContext'?");
    }
    return concern;
}
/**
 * This should be called in any component that needs to access the RSVP context, IF THERE IS ONE.
 * If no context is found, it will return null.
 */
export function useRSVPContextOptional() {
    return inject("RSVPConcern", null);
}
