import { render, staticRenderFns } from "./EventCard.vue?vue&type=template&id=84cc5926&lang=pug&"
import script from "./EventCard.vue?vue&type=script&lang=ts&"
export * from "./EventCard.vue?vue&type=script&lang=ts&"
import style0 from "./EventCard.vue?vue&type=style&index=0&id=84cc5926&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports