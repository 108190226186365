import { defineComponent } from "vue";
export default defineComponent({
    name: "IconButton",
    props: {
        icon: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: false,
        },
        tooltip: {
            type: String,
            required: false,
        },
    },
});
