/**
 *    CONTEXT ARCHITECTURE
 *    --------------------
 *
 *    X ---------> Y == Y depends on X
 *
 *    -------------------------
 *   |    Event Vuex Store    |
 *   -------------------------
 *              |              \
 *              |               \
 *              |                \
 *              |                 \
 *              |                  v
 *              |            -----------------------------
 *              |           |  OwnUserAttendance-Concern |
 *              |           -----------------------------
 *              |            /
 *              |          /
 *              V        V
 *          -----------------
 *         |  RSVP-Concern  |
 *         ------------------
 *
 */
import { __assign, __awaiter, __generator } from "tslib";
import { defineComponent, getCurrentInstance, inject, ref, watch, } from "vue";
import moment from "moment";
import { createNamespacedHelpers } from "vuex";
var _a = createNamespacedHelpers("event"), mapEventState = _a.mapState, mapEventActions = _a.mapActions, mapEventGetters = _a.mapGetters, mapEventMutations = _a.mapMutations;
var _b = createNamespacedHelpers("member"), mapMemberGetters = _b.mapGetters, mapMemberActions = _b.mapActions;
import EventCard from "./EventCard.vue";
import EventDetails from "./EventDetails.vue";
import EventExpansionPanel from "./EventExpansionPanel.vue";
import Header from "../UI/Header.vue";
import ErrorHero from "../UI/ErrorHero.vue";
import FlagTag from "../UI/FlagTag.vue";
import Tag from "../UI/Tag.vue";
import MailedIcon from "../icons/MailedIcon.vue";
import Sheet from "../UI/Sheet.vue";
import EventForm from "../EventForm/EventForm.vue";
import { mdiDownload, mdiPencilOutline } from "@mdi/js";
import { mdiPlus } from "@mdi/js";
import { mdiCheck } from "@mdi/js";
import "suus-api";
import { MediaUploaderService } from "features/media/media-uploader.service";
import { eventsApi } from "store/api";
import EventSheetButton from "components/Event/EventSheetButton.vue";
import EventPostList from "features/events/posts/EventPostList.vue";
import "concerns/useContextName";
import { GalleryService } from "features/gallery/service/gallery.service";
import { addGalleyMediumToHighlights, removeHighlight, reorderHighlights, } from "features/events/highlights/highlights.functions";
import { EventLightboxService } from "features/events/highlights/lightbox/event-lightbox.service";
import "features/gallery/lightbox/lightbox.service";
import { EVENT_STATUS } from "constants/constants";
import { download } from "app/util/download.helper";
import { provideRSVPContext } from "app/modules/events/rsvp-context/use-rsvp-context.concern";
import store from "store";
import { provideOwnUserAttendanceContext } from "app/modules/events/use-own-user-attendance-context.concern";
export default defineComponent({
    name: "EventPage",
    components: {
        EventPostList: EventPostList,
        EventSheetButton: EventSheetButton,
        EventCard: EventCard,
        Header: Header,
        EventDetails: EventDetails,
        EventExpansionPanel: EventExpansionPanel,
        ErrorHero: ErrorHero,
        FlagTag: FlagTag,
        Tag: Tag,
        Sheet: Sheet,
        EventForm: EventForm,
        MailedIcon: MailedIcon,
    },
    provide: function () {
        var _this = this;
        var _a;
        var canEdit = ref(!!this.AllowedToEdit);
        watch(function () { return _this.AllowedToEdit; }, function (val) {
            canEdit.value = !!val;
        });
        var highlightsCount = ref((_a = this.eventData) === null || _a === void 0 ? void 0 : _a.highlights_count);
        watch(function () { return _this.eventData; }, function (val) {
            highlightsCount.value = val.highlights_count;
        });
        var initialShowUploader = ref(this.attendStatus === EVENT_STATUS.EVENT_PASSED);
        var galleryService = new GalleryService(canEdit, function (galleryId) { return addGalleyMediumToHighlights(_this.eventId, galleryId); }, function (gallery) { return removeHighlight(gallery); }, reorderHighlights, highlightsCount, initialShowUploader);
        watch(function () { return _this.attendStatus; }, function (val) {
            initialShowUploader.value = val === EVENT_STATUS.EVENT_PASSED;
        }, { immediate: true });
        if (!this.lightboxService) {
            throw new Error("LightboxService not provided");
        }
        var eventLightboxService = new EventLightboxService(this.lightboxService, galleryService, this.eventId, this.viewContext);
        return {
            mediaService: this.attachmentUploaderService,
            GalleryService: galleryService,
            EventLightboxService: eventLightboxService,
        };
    },
    data: function () {
        return {
            editIcon: mdiPencilOutline,
            downloadIcon: mdiDownload,
            addIcon: mdiPlus,
            doneIcon: mdiCheck,
            showSheet: false,
            sheetScrollTo: "",
        };
    },
    computed: __assign(__assign(__assign(__assign({ titleText: function () {
            return this.$i18n.t("components.event.error.titleText").toString();
        }, line1: function () {
            return this.$i18n.t("components.event.error.line1").toString();
        }, line2: function () {
            return this.$i18n.t("components.event.error.line2").toString();
        }, btnLabel: function () {
            return this.$i18n.t("components.event.error.btnLabel").toString();
        }, mailedTag: function () {
            return this.$i18n.t("components.event.urgent").toString();
        }, mailedDetails: function () {
            var _a, _b, _c;
            var mailed_at = moment(this.eventData.mailed_at).format("dd. DD.MM.YYYY, HH:mm:ss");
            var context_name = this.getContextName(((_a = this.eventData) === null || _a === void 0 ? void 0 : _a.level_id) || undefined, (_b = this.eventData.mailed_by) === null || _b === void 0 ? void 0 : _b.umbrella_name_long, (_c = this.eventData.mailed_by) === null || _c === void 0 ? void 0 : _c.internal_name_long);
            var details = "Dringende E-Mail versandt ".concat(context_name && "durch ".concat(context_name), " am ").concat(mailed_at, " Uhr");
            return details;
        }, title: function () {
            return this.eventData && this.eventData.title;
        }, levelLabel: function () {
            return this.eventData &&
                this.eventData.organization &&
                this.eventData.organization.type === "Level"
                ? this.eventData.organization.name
                : null;
        }, isPublic: function () {
            return !!(this.eventData && this.eventData.is_public);
        }, btnStyles: function () {
            return {
                fontFamily: "system-ui",
                fontWeight: 700,
                letterSpacing: "0.05em",
            };
        }, AllowedToPublish: function () {
            var _a, _b, _c, _d;
            return (this.canPublish &&
                (((_b = (_a = this.eventData) === null || _a === void 0 ? void 0 : _a.links) === null || _b === void 0 ? void 0 : _b.publish_url) ||
                    ((_d = (_c = this.eventData) === null || _c === void 0 ? void 0 : _c.links) === null || _d === void 0 ? void 0 : _d.unpublish_url)));
        }, AllowedToEdit: function () {
            return (this.eventData &&
                this.eventData.links &&
                this.eventData.links.update_url);
        }, loading: function () {
            return !!this.eventLoading;
        }, renderPosts: function () {
            return !!this.eventData;
        }, canCreatePosts: function () {
            var _a, _b;
            return !!((_b = (_a = this.eventData) === null || _a === void 0 ? void 0 : _a.links.posts) === null || _b === void 0 ? void 0 : _b.create_url);
        }, postsCount: function () {
            var _a;
            return ((_a = this.eventData) === null || _a === void 0 ? void 0 : _a.posts_count) || 0;
        }, 
        //gets event id from url
        eventId: function () {
            return parseInt(this.$route.params.event_id);
        }, viewContext: function () {
            var _a, _b, _c, _d, _e;
            return {
                group: (_a = this.eventData) === null || _a === void 0 ? void 0 : _a.group,
                client_id: (_c = (_b = this.eventData) === null || _b === void 0 ? void 0 : _b.group) === null || _c === void 0 ? void 0 : _c.client_id,
                level_id: (_e = (_d = this.eventData) === null || _d === void 0 ? void 0 : _d.group) === null || _e === void 0 ? void 0 : _e.level_id,
            };
        } }, mapEventState({
        eventData: function (state) { return state.currentEvent.data; },
        eventLoading: function (state) { return state.currentEvent.loading; },
    })), mapEventGetters(["hasError"])), mapMemberGetters(["canPublish"])), { hasErrorInEventOrAttendance: function () {
            return this.hasError || !!this.ownUserAttendanceError || !!this.error;
        } }),
    methods: __assign(__assign(__assign({ getContextName: function (level_id, umbrella_name, client_name) {
            return level_id ? umbrella_name : client_name;
        }, onBtnClick: function () {
            window.location.href = "/";
        }, openSheetWithAnimation: function (selector) {
            ;
            this.$refs.eventSheetButton.openSheet();
            this.showSheet = true;
            this.sheetScrollTo = selector;
            this.setupForm();
        }, handlePublish: function () {
            if (this.isPublic) {
                this.deletePublic(this.eventData.id);
            }
            else {
                this.addPublic(this.eventData.id);
            }
        }, handleEditEvent: function (openSheet) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.setupForm()];
                        case 1:
                            _a.sent();
                            openSheet();
                            return [2 /*return*/];
                    }
                });
            });
        }, setupForm: function () {
            return __awaiter(this, void 0, Promise, function () {
                var attachments, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.attachmentUploaderService.reset();
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, eventsApi.getEventAttachments(this.eventData.id.toString())];
                        case 2:
                            attachments = _a.sent();
                            this.UPDATE_EVENT_ATTRIBUTE({
                                key: "attachments",
                                val: attachments === null || attachments === void 0 ? void 0 : attachments.data,
                            });
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _a.sent();
                            console.error("Error while fetching attachments: ", e_1);
                            return [3 /*break*/, 4];
                        case 4:
                            this.setFormData();
                            return [2 /*return*/];
                    }
                });
            });
        } }, mapEventMutations(["UPDATE_EVENT_ATTRIBUTE"])), mapEventActions([
        "getEvent",
        "setFormData",
        "addPublic",
        "deletePublic",
    ])), mapMemberActions(["getCurrentMember"])),
    created: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCurrentMember()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    setup: function () {
        var _this = this;
        var _a;
        var attachmentUploaderService = new MediaUploaderService();
        var lightboxService = inject("lightboxService");
        var downloadIcs = function () {
            download(window.location.origin + window.location.pathname + ".ics");
        };
        var currentInstance = getCurrentInstance();
        var eventId = (_a = currentInstance.proxy) === null || _a === void 0 ? void 0 : _a.$route.params.event_id;
        var event$ = store.dispatch("event/getEvent", eventId);
        var ownUserAttendanceContext = provideOwnUserAttendanceContext(eventId);
        var _b = provideRSVPContext(eventId, function () { return __awaiter(_this, void 0, void 0, function () {
            var event;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, eventsApi.getEvent(eventId).then(function (res) { return res.data; })];
                    case 1:
                        event = _d.sent();
                        return [2 /*return*/, {
                                attendsCount: (_a = event.attends_count) !== null && _a !== void 0 ? _a : 0,
                                guestsCount: (_b = event.guests_count) !== null && _b !== void 0 ? _b : 0,
                                declinesCount: (_c = event.declines_count) !== null && _c !== void 0 ? _c : 0,
                            }];
                }
            });
        }); }, ownUserAttendanceContext), initRSVPConcern = _b.initRSVPConcern, preloadRSVPs = _b.preloadRSVPs, error = _b.error;
        event$.then(function (_event) {
            var _a, _b, _c;
            initRSVPConcern((_a = _event.attends_count) !== null && _a !== void 0 ? _a : 0, (_b = _event.guests_count) !== null && _b !== void 0 ? _b : 0, (_c = _event.declines_count) !== null && _c !== void 0 ? _c : 0);
            preloadRSVPs();
            ownUserAttendanceContext.initializeOwnUserStatus();
        });
        return {
            attachmentUploaderService: attachmentUploaderService,
            lightboxService: lightboxService,
            downloadIcs: downloadIcs,
            attendStatus: ownUserAttendanceContext.eventAttendStatus,
            ownUserAttendanceError: ownUserAttendanceContext.ownUserStatusError,
            ownUserStatusLoading: ownUserAttendanceContext.ownUserStatusLoading,
            error: error,
        };
    },
});
