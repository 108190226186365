import { EventFunctions } from "app/modules/events/event.functions";
/**
 *  This Functions-"Wrapper" is meant to improve the discoverability of the functions that do business logic on
 *  the SUUS-API objects. It's easier to find the functions you need, if you know that they are in this class, and the
 *  IDE supports you with autocompletion.
 *  This should avoid having loose functions in the modules, that are hard to find and then being duplicated by accident.
 *
 *  It increases the coupling between the TypeScript modules technically, but there shouldn't be any logic in here that
 *  influences the functional coupling between the modules. So there is (and shouldn't be) no interaction between the
 *  different Functions-Classes.
 *
 *  The only reason for this class is to improve the discoverability of the functions.
 *  Additionally, if this class is used as an entry point in other modules instead the loose functions or Function-Classes,
 *  it's easier to refactor the project structure, because you only have to change the import in this class.
 */
var FeatureFunctions = /** @class */ (function () {
    function FeatureFunctions() {
    }
    FeatureFunctions.EventFunctions = EventFunctions;
    return FeatureFunctions;
}());
export { FeatureFunctions };
