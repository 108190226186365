import { __assign } from "tslib";
import { createNamespacedHelpers } from "vuex";
var mapState = createNamespacedHelpers("event").mapState;
import { EVENT_STATUS, EVENT_URL } from "constants/constants";
import { mdiCheck } from "@mdi/js";
import { mdiClose } from "@mdi/js";
import { mdiCalendarRange } from "@mdi/js";
import { mdiMapMarkerOutline } from "@mdi/js";
import ConfettiFlag from "../graphics/ConfettiFlag.vue";
import Subtitle from "../UI/Subtitle.vue";
import Header from "../UI/Header.vue";
import Button from "../UI/Button.vue";
import TextButton from "../UI/TextButton.vue";
import TextBody from "../UI/TextBody.vue";
import Hyperlink from "../UI/Hyperlink.vue";
import StatusIcon from "../UI/StatusIcon.vue";
import GuestModal from "./GuestModal.vue";
import "suus-api";
import { defineComponent } from "vue";
import { useOwnUserAttendanceContext } from "app/modules/events/use-own-user-attendance-context.concern";
export default defineComponent({
    name: "AttendCard",
    components: {
        ConfettiFlag: ConfettiFlag,
        Header: Header,
        TextButton: TextButton,
        StatusIcon: StatusIcon,
        Hyperlink: Hyperlink,
        TextBody: TextBody,
        Subtitle: Subtitle,
        Button: Button,
        GuestModal: GuestModal,
    },
    data: function () {
        return {
            showModal: false,
            calenderIcon: mdiCalendarRange,
            placeIcon: mdiMapMarkerOutline,
            dateSeparator: " - ",
        };
    },
    methods: {
        primaryClickAttend: function () {
            var _a, _b;
            if (this.cardStatus === EVENT_STATUS.REGISTER ||
                this.cardStatus === EVENT_STATUS.DEREGISTERED) {
                this.attendFromDigest();
            }
            else if (this.cardStatus === EVENT_STATUS.REGISTERED ||
                this.cardStatus === EVENT_STATUS.FINISHED_REGISTRATION) {
                window.open((_a = this.eventUrl) !== null && _a !== void 0 ? _a : undefined, "_self");
            }
            else if (this.cardStatus === EVENT_STATUS.ATTEND_ONLINE_NOW) {
                window.open((_b = this.eventData.custom_call_url) !== null && _b !== void 0 ? _b : undefined, "_self");
            }
        },
        primaryClickDecline: function () {
            var _a;
            if (this.cardStatus === EVENT_STATUS.DEREGISTERED) {
                window.open((_a = this.eventUrl) !== null && _a !== void 0 ? _a : undefined, "_self");
            }
            else {
                this.declineFromDigest();
            }
        },
        primaryClick: function () {
            return this.$route.name === "Attend"
                ? this.primaryClickAttend()
                : this.primaryClickDecline();
        },
    },
    computed: __assign({ cardAttendOptions: function () {
            return {
                register: {
                    primaryBtnLabel: this.$t("components.attend_card.card_options.register.primaryBtnLabel").toString(),
                },
                registered: {
                    primaryBtnLabel: this.$t("components.attend_card.card_options.registered.primaryBtnLabel").toString(),
                    secondaryBtnLabel: this.$t("components.attend_card.card_options.registered.secondaryBtnLabel").toString(),
                    icon: mdiCheck,
                },
                deregistered: {
                    primaryBtnLabel: this.$t("components.attend_card.card_options.register.primaryBtnLabel").toString(),
                },
                finished_registration: {
                    primaryBtnLabel: this.$t("components.attend_card.card_options.finished_registration.primaryBtnLabel").toString(),
                    secondaryBtnLabel: this.$t("components.attend_card.card_options.finished_registration.secondaryBtnLabel").toString(),
                    icon: mdiCheck,
                },
                attend_online_now: {
                    primaryBtnLabel: this.$t("components.attend_card.card_options.attend_online_now.primaryBtnLabel").toString(),
                    icon: mdiCheck,
                },
                event_passed: {},
            };
        }, cardDeclineOptions: function () {
            return {
                register: {
                    primaryBtnLabel: this.$t("declines.decline").toString(),
                },
                deregistered: {
                    primaryBtnLabel: "Zur Veranstaltung",
                    icon: mdiClose,
                },
                registered: {
                    primaryBtnLabel: this.$t("declines.decline").toString(),
                },
                finished_registration: {
                    primaryBtnLabel: this.$t("declines.decline").toString(),
                },
                attend_online_now: {
                    primaryBtnLabel: this.$t("declines.decline").toString(),
                },
                event_passed: {},
            };
        }, cardOptions: function () {
            return this.$route.name === "Attend"
                ? this.cardAttendOptions
                : this.cardDeclineOptions;
        }, loading: function () {
            return this.eventLoading || this.ownUserStatusLoading;
        }, eventId: function () {
            return parseInt(this.$route.params.event_id);
        }, title: function () {
            return this.eventData && this.eventData.title;
        }, location: function () {
            return this.eventData && this.eventData.location;
        }, showEventDetails: function () {
            return (this.$route.name === "Decline" ||
                this.cardStatus === EVENT_STATUS.REGISTER ||
                this.cardStatus === EVENT_STATUS.DEREGISTERED ||
                this.cardStatus === EVENT_STATUS.EVENT_PASSED ||
                this.cardStatus === EVENT_STATUS.ATTEND_ONLINE_NOW);
        }, confettiFlagClasses: function () {
            return [
                "suus__grapic d-none d-sm-flex mb-4",
                "".concat((this.cardStatus === EVENT_STATUS.EVENT_PASSED ||
                    this.cardStatus === EVENT_STATUS.DEREGISTERED) &&
                    "disabled"),
            ];
        }, primaryBtnLabel: function () {
            if (this.cardOptions[this.cardStatus].primaryBtnLabel) {
                return this.cardOptions[this.cardStatus].primaryBtnLabel || null;
            }
            return null;
        }, secondaryBtnLabel: function () {
            if (this.cardOptions[this.cardStatus].secondaryBtnLabel &&
                this.allow_guests) {
                return this.cardOptions[this.cardStatus].secondaryBtnLabel || null;
            }
            return null;
        }, icon: function () {
            if (this.cardOptions[this.cardStatus].icon) {
                return this.cardOptions[this.cardStatus].icon || null;
            }
            return null;
        }, confirmationMessage: function () {
            if (this.cardStatus === EVENT_STATUS.REGISTERED && this.allow_guests) {
                return this.$i18n
                    .t("components.confirmationMessage.REGISTERED", [this.ownUserName])
                    .toString();
            }
            if (this.cardStatus === EVENT_STATUS.FINISHED_REGISTRATION &&
                !this.allow_guests) {
                return this.$i18n
                    .t("components.confirmationMessage.NO_GUESTS_ALLOWED", [
                    this.ownUserName,
                ])
                    .toString();
            }
            if (this.cardStatus === EVENT_STATUS.FINISHED_REGISTRATION &&
                this.allow_guests) {
                return this.ownUserGuestsCount === 1
                    ? this.$i18n
                        .t("components.confirmationMessage.GUESTS_ALLOWED.one", [
                        this.ownUserName,
                    ])
                        .toString()
                    : this.$i18n
                        .t("components.confirmationMessage.GUESTS_ALLOWED.more", [
                        this.ownUserName,
                        this.ownUserGuestsCount,
                    ])
                        .toString();
            }
            return null;
        }, eventTitleLevel: function () {
            return this.$vuetify.breakpoint.name === "xs" ? 4 : 1;
        }, graphicWidth: function () {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "180";
                case "sm":
                    return "100%";
                default:
                    return "250";
            }
        }, modalWidth: function () {
            // TODO: Refactor into mixin, also used in EventAttendeeList
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 600;
                case "sm":
                    return 800;
                case "md":
                    return 600;
                default:
                    return 800;
            }
        }, eventUrl: function () {
            return this.eventData ? "".concat(EVENT_URL).concat(this.eventData.id) : null;
        }, showEndDate: function () {
            if (this.eventData &&
                this.eventData.end_at &&
                this.eventData.end_at !== this.eventData.start_at) {
                return true;
            }
            return false;
        }, eventStartDate: function () {
            if (this.eventData && this.eventData.start_at) {
                return this.eventData.start_at;
            }
            return null;
        }, eventEndDate: function () {
            if (this.showEndDate && this.eventData && this.eventData.end_at) {
                return this.eventData.end_at;
            }
            return null;
        } }, mapState({
        eventData: function (state) { return state.currentEvent.data; },
        eventLoading: function (state) { return state.currentEvent.loading; },
        allow_guests: function (state) {
            return (state.currentEvent.data &&
                state.currentEvent.data.allow_guests);
        },
    })),
    setup: function () {
        var _a = useOwnUserAttendanceContext(), eventAttendStatus = _a.eventAttendStatus, attendFromDigest = _a.attendFromDigest, declineFromDigest = _a.declineFromDigest, ownUserGuestsCount = _a.ownUserGuestsCount, ownUserStatusLoading = _a.ownUserStatusLoading, ownUserName = _a.ownUserName;
        return {
            cardStatus: eventAttendStatus,
            attendFromDigest: attendFromDigest,
            declineFromDigest: declineFromDigest,
            ownUserGuestsCount: ownUserGuestsCount,
            ownUserStatusLoading: ownUserStatusLoading,
            ownUserName: ownUserName,
        };
    },
});
