import "vue";
export function updateWhere(list, where, update) {
    var index = list.value.findIndex(where);
    if (index >= 0) {
        list.value[index] = update(list.value[index]);
    }
    return index >= 0;
}
export function removeWhere(list, where) {
    var originalLength = list.value.length;
    list.value = list.value.filter(function (item) { return !where(item); });
    return list.value.length !== originalLength;
}
