<template lang="pug">
div
  .header.context
    div
      .mobile.only
        a.ui.icon.button(@click.prevent="goBack")
          i.icon.chevron.left
          | Zurück

      Breadcrumbs.mobile.hidden(:path="path")
        template(#item="{item}")
          component(:is="`${item.type}Breadcrumb`" :inode="item")

        template(#omission="{omittedItems}")
          router-link.ui.icon.button.tiny(
            :to="{name: 'drive/folder', params: { inode_id: omittedItems.slice(-1)[0].id} }"
          ) …

    .ui.icon.buttons
      slot(name="buttons")

  h1.ui.header
    .content
      i.ui.icon.suus-connect(v-if="currentInode.level" v-tooltip="$t('components.drive.header.other_client')")
      template(v-if="currentInode.name") {{ currentInode.name }}
      template(v-else) {{currentInode.parent.name}} vom {{currentInode.physically_created_at_formatted}}
      i.ui.icon.suus-lock-circle(v-if="currentInode.restrict_to" v-tooltip="`Nur Mitglieder der Gruppe '${currentInode.restrict_to.name}' haben Zugriff.`")
      i.ui.icon.suus-hourglass(v-if="unsaved" v-tooltip="`Diese Datei wurde erst vor wenigen Minuten hochgeladen und wurde vom System noch nicht fertig verarbeitet und dauerhaft abgespeichert. In wenigen Minuten ist die Datei fertig gespeichert und diese Meldung verschwindet, du musst nichts tun.`")
    
    .sub.header {{ currentInode.description }}

    .meta
      // Level
      span(v-if="currentInode.level" )
        i.ui.icon.suus-connect
        i18n(tag="span" path="drive.header.level")
          a(slot="level" :href="currentInode.level.link") {{ currentInode.level.name }}

      // Typ of Folder
      span(v-if="currentInode.type === 'Container'")
        i.ui.icon.suus-single-copies
        span Serie
      span(v-if="currentInode.type === 'Gallery'")
        i.ui.icon.suus-single-copies
        span Galerie

      // Read
      span
        template(v-if="currentInode.restrict_to")
          i.ui.icon.suus-lock-circle
          i18n(tag="span" path="drive.header.restrict_to")
            a(slot="restrict_to" :href="currentInode.restrict_to.link") {{ currentInode.restrict_to.name }}
        template(v-else)
          i.ui.icon.suus-eye-17
          i18n(tag="span" path="drive.header.restrict_to")
            template(slot="restrict_to") Alle

      // Write
      span(v-if="currentInode.owner")
        i.ui.icon.suus-edit-75
        i18n(tag="span" path="drive.header.owner")
          a(slot="owner" :href="currentInode.owner.link")
            template(v-if="currentInode.owner_type === 'Group'") {{ currentInode.owner.name }}
            template(v-else-if="currentInode.owner_type === 'Client'") {{ currentInode.owner.name }}
            template(v-else-if="currentInode.owner.personal_record_id") {{ currentInode.owner.internal_name_short }}
            template(v-else)
              MemberLink(:member="currentInode.owner" :context="context")

      // Creator
      span(v-if="currentInode.created_at")
        i.ui.icon.suus-time-3
        i18n(tag="span" path="drive.header.creator")
          template(slot="created_at") {{ currentInode.created_at | formatDate }}
          template(slot="creator" v-if="currentInode.uploaded_by")
            MemberLink(:member="currentInode.uploaded_by" :context="context")
          template(v-else slot="creator")
            | Unbekannt

      span(v-if="currentInode.type === 'DriveFile' || currentInode.type === 'ContainerFile' || currentInode.type === 'Photo'")
        i.ui.icon.suus-hit-down
        | {{ currentInode.download_count }}x
        span.mobile.only
          |
          | heruntergeladen

      span(v-if="currentInode.type === 'DriveFile' || currentInode.type === 'ContainerFile' || currentInode.type === 'Photo'")
        i.ui.icon.suus-disk-reader
        span {{ currentInode.file_size | filesize }}

      span(v-if="currentInode.type === 'Folder' || currentInode.type === 'Container'")
        i.ui.icon.suus-layers-3
        span {{$tc('drive.header.files_count', currentInode.inodes_count)}}

</template>

<script>
import { createNamespacedHelpers } from "vuex"
import handleBack from "../../mixins/handleBack"
const { mapGetters, mapState, mapMutations, mapActions } =
  createNamespacedHelpers("drive")

import ContainerFileBreadcrumb from "./Breadcrumbs/ContainerFileBreadcrumb.vue"
import FolderBreadcrumb from "./Breadcrumbs/FolderBreadcrumb.vue"
import ContainerBreadcrumb from "./Breadcrumbs/ContainerBreadcrumb.vue"
import DriveFileBreadcrumb from "./Breadcrumbs/DriveFileBreadcrumb.vue"
import PhotoBreadcrumb from "./Breadcrumbs/PhotoBreadcrumb.vue"
import GalleryBreadcrumb from "./Breadcrumbs/GalleryBreadcrumb.vue"
import Pin from "./Pin.vue"
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs.vue"
import MemberLink from "../MemberLink.vue"

export default {
  name: "Header",
  mixins: [handleBack],
  components: {
    MemberLink,
    Breadcrumbs,
    Pin,
    GalleryBreadcrumb,
    PhotoBreadcrumb,
    DriveFileBreadcrumb,
    FolderBreadcrumb,
    ContainerFileBreadcrumb,
    ContainerBreadcrumb,
  },
  computed: {
    unsaved() {
      return (
        this.currentInode &&
        this.currentInode.stored_file &&
        this.currentInode.stored_file.startsWith("tempUploads")
      )
    },
    context() {
      return {
        level_id: this.currentInode.level_id,
        client_id: this.currentInode.client_id,
      }
    },
    ...mapState(["path"]),
    ...mapGetters(["currentInode", "parentFolder", "path"]),
  },
  methods: {
    goBack() {
      this.handleBack({ path: "/drive" })
    },
  },
}
</script>

<style scoped></style>
