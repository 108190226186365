import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { computed, defineComponent, inject } from "vue";
import GroupMemberSingleSelect from "../GroupMemberSingleSelect.vue";
import TextBody from "../UI/TextBody.vue";
import { createNamespacedHelpers } from "vuex";
import "suus-api";
import "vue-i18n";
import "features/events/form/event-form.service";
import "components/EventForm/EventForm.vue";
import "features/common/forms/form.service";
import { PanelIdentifier } from "features/events/form/event-form.types";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "features/member/member.service";
import { fetchGroupMembers, fetchGroups } from "features/group/group.functions";
var _a = createNamespacedHelpers("event"), mapEventState = _a.mapState, mapEventMutations = _a.mapMutations;
var mapMemberGetters = createNamespacedHelpers("member").mapGetters;
var OrganizerFields = defineComponent({
    name: "OrganizerFields",
    components: { GroupMemberSingleSelect: GroupMemberSingleSelect, TextBody: TextBody },
    data: function () {
        return {
            groups: [],
            members: [],
            searchParam: null,
        };
    },
    computed: __assign(__assign({ isEditForm: function () {
            var _a;
            return !!((_a = this.eventForm) === null || _a === void 0 ? void 0 : _a.id);
        }, infoText: function () {
            return this.$t("components.event_form.please_choose_contact");
        }, getResultParams: function () {
            var _a, _b, _c;
            return {
                group_id: (_a = this.group) === null || _a === void 0 ? void 0 : _a.id,
                client_id: (_b = this.group) === null || _b === void 0 ? void 0 : _b.client_id,
                level_id: (_c = this.group) === null || _c === void 0 ? void 0 : _c.level_id,
            };
        }, contactRules: function () {
            var _this = this;
            return [
                function (v) { return !!v || _this.$t("components.event_form.please_enter_contact"); },
            ];
        }, group: function () {
            var _a;
            return (_a = this.eventForm) === null || _a === void 0 ? void 0 : _a.group;
        }, specifyGroupSearch: function () {
            var _a, _b;
            return ((_a = this.groups) === null || _a === void 0 ? void 0 : _a.length) >= 100 || ((_b = this.members) === null || _b === void 0 ? void 0 : _b.length) >= 20;
        } }, mapEventState(["eventForm"])), mapMemberGetters(["memberLevelIds", "currentClient"])),
    methods: __assign({ getResults: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var client_id, level_id, groups, groupMembers;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            client_id = params.client_id, level_id = params.level_id;
                            return [4 /*yield*/, fetchGroups(client_id, level_id)];
                        case 1:
                            groups = _a.sent();
                            return [4 /*yield*/, fetchGroupMembers(this.searchParam, level_id)];
                        case 2:
                            groupMembers = _a.sent();
                            return [2 /*return*/, __spreadArray(__spreadArray([], groupMembers, true), groups, true)];
                    }
                });
            });
        }, updateSearch: function (val) {
            this.searchParam = val;
        } }, mapEventMutations(["updateEventForm"])),
    setup: function () {
        var t$ = useTranslate("components.event_form").t$;
        var eventFormService = inject("EventFormService");
        var memberService = inject("MemberService");
        var contactRule = {
            field: "contact",
            message: t$(".validate_contact").toString(),
            selector: "event-form__contact",
            panelIdentifier: PanelIdentifier.GENERAL,
            action: function (v) { return !!v && ("name" in v ? !!v.name.length : true); },
        };
        eventFormService.registerRule(contactRule);
        eventFormService.registerProperty({
            name: "contact",
        });
        eventFormService.registerProperty({
            name: "contact_type",
        });
        var setContactType = function (contact) {
            if (!contact) {
                eventFormService.setFormValue("contact_type", null);
            }
            else {
                // Only groups have a "type" attribute indicating the specific type of the Group
                var contact_type = !!contact && "type" in contact ? "Group" : "GroupMember";
                eventFormService.setFormValue("contact_type", contact_type);
            }
        };
        var contact = computed({
            get: function () {
                return eventFormService.getFormValue("contact").value;
            },
            set: function (value) {
                eventFormService.setFormValue("contact", value);
                setContactType(value);
                eventFormService.validate();
            },
        });
        memberService.getCurrentGroupMember().then(function (member) {
            if (member && contact.value == undefined) {
                contact.value = member;
            }
        });
        return {
            contact: contact,
        };
    },
});
export default OrganizerFields;
