import Vue, { provide } from "vue";
import PortalSearch from "./PortalSearch.vue";
import WebPushDialog from "features/push/WebPushDialog.vue";
import InstallPWADialog from "features/push/InstallPWADialog.vue";
import store from "store";
import MemberService from "features/member/member.service";
import GroupService from "features/group/group.service";
import GalleryDashboardPortal from "features/gallery/dashboard/GalleryDashboardPortal.vue";
import CustomAttributesManagementRoot from "app/modules/custom-attributes/CustomAttributesManagementPortal.vue";
import SnackbarProvider from "app/ui-primitives/snackbars/SnackbarProvider.vue";
import ConfirmationDialogProvider from "features/common/confirmation-dialog/ConfirmationDialogProvider.vue";
import LevelSettings from "app/modules/organization/levels/LevelSettings.vue";
export default Vue.extend({
    name: "PortalApp",
    components: {
        LevelSettings: LevelSettings,
        ConfirmationDialogProvider: ConfirmationDialogProvider,
        SnackbarProvider: SnackbarProvider,
        CustomAttributesManagementRoot: CustomAttributesManagementRoot,
        GalleryDashboardPortal: GalleryDashboardPortal,
        PortalSearch: PortalSearch,
        WebPushDialog: WebPushDialog,
        InstallPWADialog: InstallPWADialog /*Lightbox*/,
    },
    setup: function () {
        var memberService = new MemberService(store);
        provide("MemberService", memberService);
        var groupService = new GroupService(store);
        provide("GroupService", groupService);
    },
});
