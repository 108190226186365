import { __awaiter, __generator } from "tslib";
import { defineComponent, ref } from "vue";
import { mdiClose, mdiDrag, mdiPlus } from "@mdi/js";
import Button from "components/UI/Button.vue";
import "suus-api";
import CustomAttributesUpdateErrors from "app/modules/custom-attributes/CustomAttributesUpdateErrors.vue";
import CustomAttributesList from "app/modules/custom-attributes/CustomAttributesList.vue";
import Header4 from "app/ui-primitives/header/Header4.vue";
import CustomAttributesCreate from "app/modules/custom-attributes/CustomAttributesCreateDialog.vue";
import ErrorAlert from "app/ui-primitives/alerts/ErrorAlert.vue";
import ErrorSnackbar from "app/ui-primitives/snackbars/ErrorSnackbar.vue";
import SuccessSnackbar from "app/ui-primitives/snackbars/SuccessSnackbar.vue";
export default defineComponent({
    name: "CustomAttributesManagement",
    components: {
        SuccessSnackbar: SuccessSnackbar,
        ErrorSnackbar: ErrorSnackbar,
        ErrorAlert: ErrorAlert,
        CustomAttributesCreate: CustomAttributesCreate,
        Header4: Header4,
        CustomAttributesList: CustomAttributesList,
        CustomAttributesUpdateErrors: CustomAttributesUpdateErrors,
        Button: Button,
    },
    setup: function () {
        var _this = this;
        var fetchError = ref(null);
        var handleFetchError = function (reset) {
            if (reset) {
                fetchError.value = null;
            }
            else {
                fetchError.value =
                    "Beim Laden der Feldes ist ein Fehler aufgetreten. Versuche die Seite neu zu laden.";
            }
        };
        var personalList = ref(null);
        var clubList = ref(null);
        var handleCreation = function (customAttribute) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                if (customAttribute.attributable_type === "PersonalRecord") {
                    (_a = personalList.value) === null || _a === void 0 ? void 0 : _a.addAttribute(customAttribute);
                }
                else {
                    (_b = clubList.value) === null || _b === void 0 ? void 0 : _b.addAttribute(customAttribute);
                }
                return [2 /*return*/];
            });
        }); };
        var handleMoved = function (_a) {
            var movedAttribute = _a.movedAttribute, targetList = _a.targetList;
            return __awaiter(_this, void 0, void 0, function () {
                var _b, _c;
                return __generator(this, function (_d) {
                    if (targetList === "PersonalRecord") {
                        (_b = personalList.value) === null || _b === void 0 ? void 0 : _b.addAttribute(movedAttribute);
                    }
                    else {
                        (_c = clubList.value) === null || _c === void 0 ? void 0 : _c.addAttribute(movedAttribute);
                    }
                    return [2 /*return*/];
                });
            });
        };
        return {
            mdiDrag: mdiDrag,
            mdiPlus: mdiPlus,
            handleCreation: handleCreation,
            handleMoved: handleMoved,
            mdiClose: mdiClose,
            handleFetchError: handleFetchError,
            fetchError: fetchError,
            personalList: personalList,
            clubList: clubList,
        };
    },
});
