import { __assign } from "tslib";
import { computed, defineComponent, ref, watch } from "vue";
import InputField from "app/ui-primitives/forms/InputField.vue";
import Select from "app/ui-primitives/forms/Select.vue";
import CustomAttributesSelectValues from "app/modules/custom-attributes/CustomAttributesSelectValues.vue";
import Button from "components/UI/Button.vue";
import TextButton from "components/UI/TextButton.vue";
import "suus-api";
import ErrorText from "app/ui-primitives/text/ErrorText.vue";
import { mdiHelpCircle } from "@mdi/js";
import "app/ui-primitives/forms/ContentfulSelect.vue";
import ContentfulSelect from "app/ui-primitives/forms/ContentfulSelect.vue";
import ChipSelectCombo from "app/ui-primitives/forms/ChipSelectCombo.vue";
import CustomAttributeTypeSelection from "app/modules/custom-attributes/form/CustomAttributeTypeSelection.vue";
import { CUSTOM_ATTRIBUTE_READ_ACCESS_ICONS, CUSTOM_ATTRIBUTE_WRITE_ACCESS_ICONS, } from "app/modules/custom-attributes/types";
var READ_ACCESS_SELECTION_ITEMS = [
    {
        text: "Admins",
        value: "readable_by_admins",
        icon: CUSTOM_ATTRIBUTE_READ_ACCESS_ICONS.readable_by_admins.icon,
        description: "<b>Admins</b> können dieses Feld ansehen",
    },
    {
        text: "Adressverwalter",
        value: "readable_by_managers",
        icon: CUSTOM_ATTRIBUTE_READ_ACCESS_ICONS.readable_by_managers.icon,
        description: "<b>Adressverwalter</b> & <b>Admins</b> können dieses Feld ansehen",
    },
    {
        text: "Alle Bearbeiter",
        value: "readable_by_editors",
        icon: CUSTOM_ATTRIBUTE_READ_ACCESS_ICONS.readable_by_editors.icon,
        description: "Alle Bearbeiter (<b>Admins</b>, <b>Adressverwalter</b> & <b>Profilbesitzer</b>) können dieses Feld ansehen",
    },
    {
        text: "Alle",
        value: "readable_by_viewers",
        icon: CUSTOM_ATTRIBUTE_READ_ACCESS_ICONS.readable_by_viewers.icon,
        description: "<b>Alle Nutzer</b> können dieses Feld ansehen",
    },
];
var WRITE_ACCESS_SELECTION_ITEMS = [
    {
        text: "Admins",
        value: "writeable_by_admins",
        icon: CUSTOM_ATTRIBUTE_WRITE_ACCESS_ICONS.writeable_by_admins.icon,
        description: "<b>Admins</b> können dieses Feld bearbeiten",
    },
    {
        text: "Adressverwalter",
        value: "writeable_by_managers",
        icon: CUSTOM_ATTRIBUTE_WRITE_ACCESS_ICONS.writeable_by_managers.icon,
        description: "<b>Adressverwalter</b> & <b>Admins</b> können dieses Feld bearbeiten",
    },
    {
        text: "Alle Bearbeiter",
        value: "writeable_by_editors",
        icon: CUSTOM_ATTRIBUTE_WRITE_ACCESS_ICONS.writeable_by_editors.icon,
        description: "Alle Bearbeiter (<b>Admins</b>, <b>Adressverwalter</b> & <b>Profilbesitzer</b>) können dieses Feld bearbeiten",
    },
];
/**
 * API:
 * @function submitForm - triggers the submit function & validation
 * @function resetForm - triggers the reset function
 * @emits commit - when save was triggered and the form is valid and should be committed
 * @emits dirty - when the form is dirty (or create mode)
 */
export default defineComponent({
    name: "CustomAttributesForm",
    components: {
        CustomAttributeTypeSelection: CustomAttributeTypeSelection,
        ChipSelectCombo: ChipSelectCombo,
        ContentfulSelect: ContentfulSelect,
        ErrorText: ErrorText,
        TextButton: TextButton,
        Button: Button,
        CustomAttributesSelectValues: CustomAttributesSelectValues,
        Select: Select,
        InputField: InputField,
    },
    props: {
        initialValue: {
            type: Object,
            hide_when_empty: true,
        },
    },
    setup: function (props, _a) {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        var emit = _a.emit;
        var formRef = ref(null);
        var isCreate = computed(function () { return props.initialValue == undefined; });
        var attributeName = ref((_c = (_b = props.initialValue) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : "");
        var attributeDescription = ref((_e = (_d = props.initialValue) === null || _d === void 0 ? void 0 : _d.description) !== null && _e !== void 0 ? _e : "");
        var attributeType = ref((_g = (_f = props.initialValue) === null || _f === void 0 ? void 0 : _f.attribute_type) !== null && _g !== void 0 ? _g : "String");
        var selectionValues = ref((_j = (_h = props.initialValue) === null || _h === void 0 ? void 0 : _h.config) !== null && _j !== void 0 ? _j : []);
        var hide_when_empty = ref((_l = (_k = props.initialValue) === null || _k === void 0 ? void 0 : _k.hide_when_empty) !== null && _l !== void 0 ? _l : false);
        var readAccess = ref((_o = (_m = props.initialValue) === null || _m === void 0 ? void 0 : _m.read_access) !== null && _o !== void 0 ? _o : "readable_by_admins");
        var writeAccess = ref((_q = (_p = props.initialValue) === null || _p === void 0 ? void 0 : _p.write_access) !== null && _q !== void 0 ? _q : "writeable_by_admins");
        watch(function () { return props.initialValue; }, function (newVal) {
            var _a, _b, _c, _d, _e, _f, _g;
            attributeName.value = (_a = newVal === null || newVal === void 0 ? void 0 : newVal.name) !== null && _a !== void 0 ? _a : "";
            attributeDescription.value = (_b = newVal === null || newVal === void 0 ? void 0 : newVal.description) !== null && _b !== void 0 ? _b : "";
            attributeType.value = (_c = newVal === null || newVal === void 0 ? void 0 : newVal.attribute_type) !== null && _c !== void 0 ? _c : "String";
            selectionValues.value = (_d = newVal === null || newVal === void 0 ? void 0 : newVal.config) !== null && _d !== void 0 ? _d : [];
            hide_when_empty.value = (_e = newVal === null || newVal === void 0 ? void 0 : newVal.hide_when_empty) !== null && _e !== void 0 ? _e : false;
            readAccess.value = (_f = newVal === null || newVal === void 0 ? void 0 : newVal.read_access) !== null && _f !== void 0 ? _f : "readable_by_admins";
            writeAccess.value = (_g = newVal === null || newVal === void 0 ? void 0 : newVal.write_access) !== null && _g !== void 0 ? _g : "writeable_by_admins";
        }, { deep: true });
        var handleTypeSelection = function (selection) {
            attributeType.value = selection;
            if (attributeType.value !== "Select") {
                selectionValues.value = [];
            }
            revalidateSelection();
        };
        var handleSelectTypeValuesChange = function (values) {
            selectionValues.value = values;
            revalidateSelection();
        };
        var isCurrentlySelectType = computed(function () {
            return attributeType.value === "Select";
        });
        var handleReadAccessSelection = function (selection) {
            readAccess.value = selection;
        };
        var selectedReadAccessSelectionItem = computed(function () {
            return READ_ACCESS_SELECTION_ITEMS.find(function (item) { return item.value === readAccess.value; });
        });
        var handleWriteAccessSelection = function (selection) {
            writeAccess.value = selection;
        };
        var selectedWriteAccessSelectionItem = computed(function () {
            return WRITE_ACCESS_SELECTION_ITEMS.find(function (item) { return item.value === writeAccess.value; });
        });
        var save = function () {
            var _a, _b;
            var _valid = validate();
            if (!_valid) {
                return;
            }
            var customAttributeDefinition = __assign(__assign({}, props.initialValue), { name: attributeName.value, description: attributeDescription.value, attribute_type: attributeType.value, config: selectionValues.value, hide_when_empty: hide_when_empty.value, attributable_type: (_b = (_a = props.initialValue) === null || _a === void 0 ? void 0 : _a.attributable_type) !== null && _b !== void 0 ? _b : "PersonalRecord", read_access: readAccess.value, write_access: writeAccess.value });
            emit("commit", customAttributeDefinition);
        };
        var reset = function () {
            var _a, _b, _c, _d, _e, _f, _g;
            var initialVal = props.initialValue;
            attributeName.value = (_a = initialVal === null || initialVal === void 0 ? void 0 : initialVal.name) !== null && _a !== void 0 ? _a : "";
            attributeDescription.value = (_b = initialVal === null || initialVal === void 0 ? void 0 : initialVal.description) !== null && _b !== void 0 ? _b : "";
            attributeType.value = (_c = initialVal === null || initialVal === void 0 ? void 0 : initialVal.attribute_type) !== null && _c !== void 0 ? _c : "String";
            selectionValues.value = (_d = initialVal === null || initialVal === void 0 ? void 0 : initialVal.config) !== null && _d !== void 0 ? _d : [];
            hide_when_empty.value = (_e = initialVal === null || initialVal === void 0 ? void 0 : initialVal.hide_when_empty) !== null && _e !== void 0 ? _e : false;
            readAccess.value = (_f = initialVal === null || initialVal === void 0 ? void 0 : initialVal.read_access) !== null && _f !== void 0 ? _f : "readable_by_admins";
            writeAccess.value = (_g = initialVal === null || initialVal === void 0 ? void 0 : initialVal.write_access) !== null && _g !== void 0 ? _g : "writeable_by_admins";
        };
        var attributeTypeChanged = computed(function () {
            var _a;
            return (attributeType.value !== ((_a = props.initialValue) === null || _a === void 0 ? void 0 : _a.attribute_type) &&
                !isCreate.value);
        });
        var dirty = computed(function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            var areSelectionTypesEqual = function (selectionValues, initialSelectionValues) {
                if (selectionValues.length !== initialSelectionValues.length) {
                    return false;
                }
                for (var i = 0; i < selectionValues.length; i++) {
                    if (selectionValues[i] !== initialSelectionValues[i]) {
                        return false;
                    }
                }
                return true;
            };
            return (attributeName.value !== ((_b = (_a = props.initialValue) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "") ||
                attributeDescription.value !==
                    ((_d = (_c = props.initialValue) === null || _c === void 0 ? void 0 : _c.description) !== null && _d !== void 0 ? _d : "") ||
                attributeType.value !==
                    ((_f = (_e = props.initialValue) === null || _e === void 0 ? void 0 : _e.attribute_type) !== null && _f !== void 0 ? _f : "String") ||
                !areSelectionTypesEqual(selectionValues.value, (_h = (_g = props.initialValue) === null || _g === void 0 ? void 0 : _g.config) !== null && _h !== void 0 ? _h : []) ||
                hide_when_empty.value !==
                    ((_k = (_j = props.initialValue) === null || _j === void 0 ? void 0 : _j.hide_when_empty) !== null && _k !== void 0 ? _k : false) ||
                readAccess.value !==
                    ((_m = (_l = props.initialValue) === null || _l === void 0 ? void 0 : _l.read_access) !== null && _m !== void 0 ? _m : "readable_by_admins") ||
                writeAccess.value !==
                    ((_p = (_o = props.initialValue) === null || _o === void 0 ? void 0 : _o.write_access) !== null && _p !== void 0 ? _p : "writeable_by_admins"));
        });
        var enableSave = computed(function () {
            return dirty.value || isCreate.value;
        });
        watch(function () { return enableSave.value; }, function (newVal) {
            emit("dirty", newVal);
        }, { immediate: true });
        var validationErrors = ref({});
        var validateSelection = function () {
            if (isCurrentlySelectType.value && selectionValues.value.length === 0) {
                return "Bitte fügen Sie mindestens einen Wert hinzu";
            }
            else {
                return undefined;
            }
        };
        var revalidateSelection = function () {
            var selectionValuesError = validateSelection();
            if (selectionValuesError) {
                validationErrors.value = __assign(__assign({}, validationErrors.value), { selectionValues: selectionValuesError });
            }
            else {
                delete validationErrors.value.selectionValues;
            }
        };
        var validate = function () {
            var isValid = formRef.value.validate();
            validationErrors.value = {};
            var errors = {};
            var selectionValuesError = validateSelection();
            if (selectionValuesError) {
                errors.selectionValues = selectionValuesError;
            }
            validationErrors.value = errors;
            return Object.keys(errors).length === 0 && isValid;
        };
        var valid = ref(false);
        var textFieldRules = [
            function (v) { return !!v || "Dieses Feld darf nicht leer sein"; },
        ];
        var submitForm = function () {
            save();
        };
        var resetForm = function () {
            reset();
        };
        return {
            attributeName: attributeName,
            attributeDescription: attributeDescription,
            attributeType: attributeType,
            selectionValues: selectionValues,
            hide_when_empty: hide_when_empty,
            readAccess: readAccess,
            writeAccess: writeAccess,
            handleTypeSelection: handleTypeSelection,
            isCurrentlySelectType: isCurrentlySelectType,
            READ_ACCESS_SELECTION_ITEMS: READ_ACCESS_SELECTION_ITEMS,
            WRITE_ACCESS_SELECTION_ITEMS: WRITE_ACCESS_SELECTION_ITEMS,
            handleReadAccessSelection: handleReadAccessSelection,
            selectedReadAccessSelectionItem: selectedReadAccessSelectionItem,
            handleWriteAccessSelection: handleWriteAccessSelection,
            selectedWriteAccessSelectionItem: selectedWriteAccessSelectionItem,
            save: save,
            cancel: reset,
            attributeTypeChanged: attributeTypeChanged,
            dirty: dirty,
            enableSave: enableSave,
            validationErrors: validationErrors,
            valid: valid,
            textFieldRules: textFieldRules,
            formRef: formRef,
            handleSelectTypeValuesChange: handleSelectTypeValuesChange,
            mdiHelpCircle: mdiHelpCircle,
            submitForm: submitForm,
            resetForm: resetForm,
        };
    },
});
