import { __assign, __awaiter, __generator } from "tslib";
import { computed, defineComponent, ref } from "vue";
import CustomAttributesUpdateErrors from "app/modules/custom-attributes/CustomAttributesUpdateErrors.vue";
import CustomAttributeValueUpdater from "features/custom_attributes/CustomAttributeValueUpdater.vue";
import "suus-api";
import { mdiClose, mdiDelete, mdiDotsVertical, mdiDrag, mdiFileMove, mdiNoteText, mdiPen, } from "@mdi/js";
import CustomAttributesEditDialog from "app/modules/custom-attributes/CustomAttributesEditDialog.vue";
import { useConfirmationService } from "features/common/confirmation-dialog/confirmation.service";
import { useIsMobile } from "features/common/vuetify/useVuetify";
import { CUSTOM_ATTRIBUTE_READ_ACCESS_ICONS, CUSTOM_ATTRIBUTE_TYPE_ICONS, CUSTOM_ATTRIBUTE_WRITE_ACCESS_ICONS, } from "app/modules/custom-attributes/types";
import PopupMenu from "app/ui-primitives/menus/popup-menu/PopupMenu.vue";
import { useSnackbar } from "app/ui-primitives/snackbars/SnackbarProvider.vue";
import Icon from "app/ui-primitives/icons/Icon.vue";
import { CustomAttributesClient, LockedException, } from "app/modules/custom-attributes/client";
export default defineComponent({
    name: "CustomAttributesListItem",
    components: {
        Icon: Icon,
        PopupMenu: PopupMenu,
        CustomAttributesEditDialog: CustomAttributesEditDialog,
        CustomAttributeValueUpdater: CustomAttributeValueUpdater,
        CustomAttributesUpdateErrors: CustomAttributesUpdateErrors,
    },
    props: {
        customAttribute: {
            type: Object,
        },
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var editOpen = ref(false);
        var openEdit = function () {
            editOpen.value = true;
        };
        var updaterOpen = ref(false);
        var openUpdater = function () {
            updaterOpen.value = true;
        };
        var confirmationService = useConfirmationService();
        var _b = useSnackbar(), showError = _b.showError, showSuccess = _b.showSuccess;
        var deleteCustomAttribute = function () { return __awaiter(_this, void 0, void 0, function () {
            var confirmed, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, confirmationService.open({
                            title: "Möchtest du dieses Feld wirklich löschen?",
                            text: "Wenn du dieses Feld löschst, kann es nicht wiederhergestellt werden.",
                            confirmText: "Löschen",
                        })];
                    case 1:
                        confirmed = _a.sent();
                        if (!confirmed) return [3 /*break*/, 5];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, CustomAttributesClient.delete(props.customAttribute.id)];
                    case 3:
                        _a.sent();
                        emit("deleted", props.customAttribute.id);
                        showSuccess("Das Feld wurde erfolgreich gelöscht.");
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        showError("Das Feld konnte nicht gelöscht werden. Stell bitte sicher, dass keine Werte mehr dafür gespeichert sind und versuche es dann erneut.");
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var menuActionLoading = ref(false);
        var confirmMove = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(props.customAttribute.attributable_type === "ClubRecord")) return [3 /*break*/, 2];
                        return [4 /*yield*/, confirmationService.open({
                                title: "Möchtest du dieses Feld wirklich verschieben?",
                                text: "Wenn du das Feld von den Mitgliedschaftsdaten zur Personendaten verschiebst, werden nur die Daten der eigenen Verbindung übertragen. Die Daten der Mitgliedschaftsdaten werden gelöscht und können nicht wiederhergestellt werden.",
                                confirmText: "Verschieben",
                            })];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _a = true;
                        _b.label = 3;
                    case 3: return [2 /*return*/, _a];
                }
            });
        }); };
        var moveCustomAttribute = function () { return __awaiter(_this, void 0, void 0, function () {
            var movedAttibute, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, confirmMove()];
                    case 1:
                        if (!(_a.sent()))
                            return [2 /*return*/];
                        menuActionLoading.value = true;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, 5, 6]);
                        return [4 /*yield*/, CustomAttributesClient.move(props.customAttribute.id, props.customAttribute.attributable_type === "PersonalRecord"
                                ? "ClubRecord"
                                : "PersonalRecord")];
                    case 3:
                        movedAttibute = _a.sent();
                        emit("moved", {
                            movedAttribute: movedAttibute,
                            targetList: movedAttibute.attributable_type,
                        });
                        return [3 /*break*/, 6];
                    case 4:
                        error_1 = _a.sent();
                        if (error_1 instanceof LockedException) {
                            showError("Es ist bereits ein Update-Vorgang dieses Feldes im Gange, bitte probiere es später erneut.");
                            emit("updated", __assign(__assign({}, props.customAttribute), { update_in_progress: true }));
                            console.warn(error_1);
                        }
                        showError("Es gab einen Fehler beim Verschieben des Feldes.");
                        console.error(error_1);
                        return [3 /*break*/, 6];
                    case 5:
                        menuActionLoading.value = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        var isMobile = useIsMobile();
        var menuItems = [
            {
                key: "edit",
                title: "Bearbeiten",
                icon: mdiPen,
                clickHandler: openEdit,
            },
            {
                key: "update",
                title: "Werte-Import",
                icon: mdiNoteText,
                clickHandler: openUpdater,
            },
            {
                key: "delete",
                title: "Löschen",
                icon: mdiDelete,
                clickHandler: deleteCustomAttribute,
            },
            {
                key: "move",
                title: "Zu ".concat(props.customAttribute.attributable_type === "PersonalRecord"
                    ? "Mitgliedsdaten"
                    : "Personendaten", " verschieben"),
                icon: mdiFileMove,
                clickHandler: moveCustomAttribute,
            },
        ];
        var attributeTypeIcon = computed(function () { return CUSTOM_ATTRIBUTE_TYPE_ICONS[props.customAttribute.attribute_type]; });
        var rAccessIcon = computed(function () {
            return CUSTOM_ATTRIBUTE_READ_ACCESS_ICONS[props.customAttribute.read_access];
        });
        var wAccessIcon = computed(function () {
            return CUSTOM_ATTRIBUTE_WRITE_ACCESS_ICONS[props.customAttribute.write_access];
        });
        var loading = computed(function () {
            return props.customAttribute.update_in_progress || menuActionLoading.value;
        });
        var showLockedHint = computed(function () {
            return props.customAttribute.update_in_progress;
        });
        var emitUpdateInProgress = function (value) {
            emit("update-in-progress", value);
        };
        return {
            mdiDrag: mdiDrag,
            mdiPen: mdiPen,
            mdiClose: mdiClose,
            mdiNoteText: mdiNoteText,
            mdiDelete: mdiDelete,
            mdiDotsVertical: mdiDotsVertical,
            editOpen: editOpen,
            updaterOpen: updaterOpen,
            menuItems: menuItems,
            isMobile: isMobile,
            loading: loading,
            attributeTypeIcon: attributeTypeIcon,
            rAccessIcon: rAccessIcon,
            wAccessIcon: wAccessIcon,
            showLockedHint: showLockedHint,
            emitUpdateInProgress: emitUpdateInProgress,
        };
    },
});
