import Vue from "vue"
export default {
  addActivePanel(state, number) {
    if (!state.activePanels.includes(number)) {
      state.activePanels = [...state.activePanels, number]
    }
  },
  SET_EVENT_DATA(state, event) {
    state.currentEvent = { ...state.currentEvent, data: event }
  },
  UPDATE_EVENT_ATTRIBUTE(state, { key, val }) {
    let newEventData = Object.assign({}, state.currentEvent.data || {}, {
      [key]: val,
    })
    state.currentEvent = { ...state.currentEvent, data: newEventData }
  },
  SET_EVENT_ERROR(state, error) {
    state.currentEvent = { ...state.currentEvent, error: error }
  },
  SET_EVENT_LOADING(state, loading) {
    state.currentEvent = { ...state.currentEvent, loading: loading }
  },
  ADD_EVENTS(state, event) {
    state.events = [...state.events, { [event.id]: event }]
  },
  UPDATE_EVENTS(state, event) {
    const newEvents = state.events.map((item, index) => {
      if (Object.keys(item)[index] !== event.id) {
        return item
      }
      return { [event.id]: event }
    })
    state.events = newEvents
  },
  DELETE_EVENT(state, event_id) {
    const newEvents = state.events.map((item, index) => {
      if (Object.keys(item)[index] !== event_id) {
        return item
      }
    })
    state.events = newEvents
  },
  SET_OPTIMISTIC_ATTACHMENTS_COUNT(state, count) {
    Vue.set(state.currentEvent, "attachmentsOptimisticCount", count)
  },
  updateEventForm(state, obj) {
    state.eventForm = { ...(state.eventForm || {}), ...obj }
  },
  setCurrEventToFormData(state) {
    state.eventForm = { ...state.currentEvent.data }
  },
}
