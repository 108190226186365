import { __spreadArray } from "tslib";
import { computed, defineComponent, onMounted, ref } from "vue";
import PopupMenu from "app/ui-primitives/menus/popup-menu/PopupMenu.vue";
import IconButton from "components/UI/IconButton.vue";
import { mdiCog } from "@mdi/js";
import CustomAttributesManagement from "app/modules/custom-attributes/CustomAttributesManagement.vue";
import { CustomAttributesClient } from "app/modules/custom-attributes/client";
import CloseableDialog from "components/UI/CloseableDialog.vue";
import Header2 from "app/ui-primitives/header/Header2.vue";
export default defineComponent({
    name: "LevelSettings",
    components: {
        Header2: Header2,
        CloseableDialog: CloseableDialog,
        CustomAttributesManagement: CustomAttributesManagement,
        PopupMenu: PopupMenu,
        IconButton: IconButton,
    },
    setup: function () {
        var render = ref(false);
        var menuItems = ref([]);
        var levelId = ref(null);
        var isSuperAdmin = ref(false);
        onMounted(function () {
            var _a, _b, _c;
            var mountingPoint = document.querySelector("#level-settings-mounting-point");
            if (mountingPoint) {
                var dataRaw = mountingPoint.getAttribute("data");
                var data = dataRaw ? JSON.parse(dataRaw) : {};
                menuItems.value = (_a = data.menu_items) !== null && _a !== void 0 ? _a : [];
                levelId.value = (_b = data.level_id) !== null && _b !== void 0 ? _b : null;
                render.value = true;
                isSuperAdmin.value = (_c = data.is_super_admin) !== null && _c !== void 0 ? _c : false;
                CustomAttributesClient.loadContext(null, levelId.value);
            }
        });
        var additionalMenuItems = [
            {
                key: "custom-attributes",
                title: "Eigene Felder bearbeiten",
                clickHandler: function () {
                    customAttributesOpen.value = true;
                },
            },
        ];
        var allMenuItems = computed(function () {
            return isSuperAdmin.value
                ? __spreadArray(__spreadArray([], menuItems.value, true), additionalMenuItems, true) : menuItems.value;
        });
        var customAttributesOpen = ref(false);
        return {
            mdiCog: mdiCog,
            allMenuItems: allMenuItems,
            levelId: levelId,
            render: render,
            customAttributesOpen: customAttributesOpen,
        };
    },
});
