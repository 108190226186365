import { __awaiter, __generator } from "tslib";
import Axios from "../../initializers/axios";
import { handleErrorResponse } from "helpers/errorHandling";
import { digestApi, eventsApi } from "../api";
import { PUBLIC_FLAG_URL, EVENT_URL } from "constants/constants";
import "suus-api";
import { parseAxiosError } from "features/common/errors/parseAxiosError";
export default {
    setFormData: function (_a) {
        var commit = _a.commit;
        commit("setCurrEventToFormData");
    },
    getEvent: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var currentEvent, event, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (event_id && state.events && state.events[event_id]) {
                            currentEvent = state.events[event_id];
                            commit("SET_EVENT_DATA", currentEvent);
                            return [2 /*return*/, currentEvent];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        commit("SET_EVENT_ERROR", false);
                        commit("SET_EVENT_LOADING", true);
                        return [4 /*yield*/, eventsApi.getEvent(event_id)];
                    case 2:
                        event = (_b.sent()).data;
                        commit("SET_EVENT_DATA", event);
                        commit("ADD_EVENTS", event);
                        if (event.is_public) {
                            commit("ADD_PUBLIC", event_id);
                        }
                        return [2 /*return*/, event];
                    case 3:
                        error_1 = _b.sent();
                        commit("SET_EVENT_ERROR", error_1);
                        handleErrorResponse(error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        commit("SET_EVENT_LOADING", false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    },
    createEvent: function (_a, request_body) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, Promise, function () {
            var event, error_2, errorMessage;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, eventsApi.createEvent(request_body)];
                    case 1:
                        event = (_b.sent()).data;
                        commit("SET_EVENT_DATA", event);
                        commit("ADD_EVENTS", event);
                        return [2 /*return*/, event];
                    case 2:
                        error_2 = _b.sent();
                        errorMessage = "Failed to create event";
                        if (error_2.response &&
                            error_2.response.data &&
                            error_2.response.data.message) {
                            errorMessage = parseAxiosError(error_2.response);
                        }
                        else {
                            throw error_2;
                        }
                        handleErrorResponse(error_2);
                        throw new Error(errorMessage);
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    updateEvent: function (_a, _b) {
        var state = _a.state, commit = _a.commit;
        var event_id = _b.event_id, request_body = _b.request_body;
        return __awaiter(this, void 0, void 0, function () {
            var event, error_3, errorMessage;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, eventsApi.updateEvent(event_id, request_body)];
                    case 1:
                        event = (_c.sent()).data;
                        commit("SET_EVENT_DATA", event);
                        commit("UPDATE_EVENTS", event);
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _c.sent();
                        errorMessage = "Failed to update event";
                        if (error_3.response &&
                            error_3.response.data.error &&
                            error_3.response.data.message) {
                            errorMessage = parseAxiosError(error_3.response);
                        }
                        else {
                            throw error_3;
                        }
                        handleErrorResponse(error_3);
                        throw new Error(errorMessage);
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    deleteEvent: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var error_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        commit("SET_EVENT_ERROR", false);
                        return [4 /*yield*/, eventsApi.deleteEvent(event_id)];
                    case 1:
                        _b.sent();
                        commit("DELETE_EVENT", event_id);
                        commit("SET_EVENT_DATA", {});
                        commit("setCurrEventToFormData");
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _b.sent();
                        commit("SET_EVENT_ERROR", error_4);
                        handleErrorResponse(error_4);
                        throw error_4;
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    getEventFromDigest: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var currentEvent, event, error_5;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (event_id && state.events && state.events[event_id]) {
                            currentEvent = state.events[event_id];
                            commit("SET_EVENT_DATA", currentEvent);
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        commit("SET_EVENT_ERROR", false);
                        commit("SET_EVENT_LOADING", true);
                        return [4 /*yield*/, digestApi.getDigestEvent(event_id)];
                    case 2:
                        event = (_b.sent()).data;
                        commit("SET_EVENT_DATA", event);
                        commit("ADD_EVENTS", event);
                        return [3 /*break*/, 5];
                    case 3:
                        error_5 = _b.sent();
                        commit("SET_EVENT_ERROR", error_5);
                        handleErrorResponse(error_5);
                        throw error_5;
                    case 4:
                        commit("SET_EVENT_LOADING", false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    },
    addPublic: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var url, data, error_6;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        url = "".concat(EVENT_URL).concat(event_id).concat(PUBLIC_FLAG_URL);
                        return [4 /*yield*/, Axios.post(url)];
                    case 1:
                        data = (_b.sent()).data;
                        commit("UPDATE_EVENT_ATTRIBUTE", { key: "is_public", val: true });
                        commit("UPDATE_EVENTS", state.currentEvent.data);
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _b.sent();
                        handleErrorResponse(error_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    deletePublic: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var url, data, error_7;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        url = "".concat(EVENT_URL).concat(event_id).concat(PUBLIC_FLAG_URL);
                        return [4 /*yield*/, Axios.delete(url)];
                    case 1:
                        data = (_b.sent()).data;
                        commit("UPDATE_EVENT_ATTRIBUTE", { key: "is_public", val: false });
                        commit("UPDATE_EVENTS", state.currentEvent.data);
                        return [3 /*break*/, 3];
                    case 2:
                        error_7 = _b.sent();
                        handleErrorResponse(error_7);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
};
