var supportedImageFormats = [
    { name: "JPEG", extensions: ["jpg", "jpeg"], mimeType: ["jpeg", "jpg"] },
    { name: "PNG", extensions: ["png"], mimeType: "png" },
    { name: "GIF", extensions: ["gif"], mimeType: "gif" },
    { name: "WEBP", extensions: ["webp"], mimeType: "webp" },
    // Partial Support only: { name: "SVG", extensions: ["svg"], mimeType: "svg+xml" },
    // Partial Support only: { name: "ICO", extensions: ["ico"], mimeType: "vnd.microsoft.icon" },
    // Partial Support only: { name: "BMP", extensions: ["bmp"], mimeType: "bmp" },
];
export var isFileSupported = function (file) {
    var mimeType = file.type.split("/").pop();
    var extension = file.name.split(".").pop();
    return supportedImageFormats.some(function (format) {
        return format.extensions.includes(extension) || format.mimeType === mimeType;
    });
};
export var isMimeTypeSupported = function (mimeType) {
    return supportedImageFormats.some(function (format) {
        var isMimeTypeArray = Array.isArray(format.mimeType);
        if (isMimeTypeArray) {
            return format.mimeType.includes(mimeType);
        }
        else {
            return format.mimeType === mimeType;
        }
    });
};
/**
 *  Returns default error message for unsupported file. If File is of type heic or heif, it will return a custom message.
 */
export var getErrorMessageHtml = function (files) {
    var mimeTypes = files.map(function (file) { return file.type.split("/").pop(); });
    var extensions = files.map(function (file) { return "." + file.name.split(".").pop(); });
    var uniqueExtensions = Array.from(new Set(extensions).values());
    if (mimeTypes.includes("heic") || mimeTypes.includes("heif")) {
        return "\n     .heic und .heif Dateiformate werden nicht unterst\u00FCtzt. \n     <br> \n     F\u00FCr iPhone-Nutzer haben wir einen kleinen Tipp: \n     <a style=\"color: white;text-decoration: underline\" href=\"https://suus.atlassian.net/wiki/spaces/GSD/pages/2005499909/Dateianh+nge+und+Bilder+im+Forum+Digest\" target=\"_blank\">Zum Hilfeartikel</a>\n    ";
    }
    return "\n    Du hast versucht Dateien vom Typ ".concat(uniqueExtensions.join(", "), " hochzuladen.\n    <br>\n    Es werden nur folgende Dateiformate unterst\u00FCtzt: ").concat(supportedImageFormats
        .map(function (format) { return format.extensions.map(function (ext) { return "." + ext; }).join(", "); })
        .join(", "), "\n    <br>\n    Du kannst die Bilder auch alternativ als Datei anh\u00E4ngen.\n  ");
};
export var getUnsupportedMessageForAttachmentImageMode = function (mimeType) {
    var _mimeType = mimeType.split("/").pop();
    return "\n    Diese Datei hat das Format \"".concat(_mimeType, "\" und kann nur als Datei angezeigt werden.\n    Verwende zum Beispiel .jpg, .jpeg oder .png Dateien, um Bilder direkt im Beitrag anzuzeigen.\n  ");
};
