import { __assign, __awaiter, __extends, __generator } from "tslib";
import "suus-api";
import { customAttributeDefinitionsApi } from "store/api";
function parseUpdateErrors(error) {
    var details = error.details;
    return details.map(function (error) { return ({
        attributable: error.attributable,
        name: error.name,
        value: error.value,
        error: error.error,
    }); });
}
var LockedException = /** @class */ (function (_super) {
    __extends(LockedException, _super);
    function LockedException(message) {
        var _this = _super.call(this, message) || this;
        _this.name = "LockedException";
        Object.setPrototypeOf(_this, LockedException.prototype);
        return _this;
    }
    return LockedException;
}(Error));
export { LockedException };
/**
 * CLIENT
 * ----------------
 * Client Functions can throw errors, for example when the API is not available.
 * The Components that use the Client should catch these errors and handle them.
 */
var currentAttributes = [];
var currentContext = {
    client_id: null,
    level_id: null,
};
var processingFetch = null;
var api = customAttributeDefinitionsApi;
function overrideCustomAttributesApi(newApi) {
    api = newApi;
}
var CustomAttributesClient = {
    loadContext: function (client_id, level_id) { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    currentContext.client_id = client_id;
                    currentContext.level_id = level_id;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    // Save the Promise, so fetch methods can wait for it.
                    processingFetch = api
                        .getCustomAttributeDefinitions(client_id === null || client_id === void 0 ? void 0 : client_id.toString(), level_id === null || level_id === void 0 ? void 0 : level_id.toString())
                        .then(function (response) { return response.data; });
                    return [4 /*yield*/, processingFetch];
                case 2:
                    currentAttributes = _a.sent();
                    return [2 /*return*/, currentAttributes];
                case 3:
                    processingFetch = null;
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); },
    fetchPersonalAttributes: function () { return __awaiter(void 0, void 0, Promise, function () {
        var filter;
        return __generator(this, function (_a) {
            filter = function (attrs) {
                return attrs.filter(function (attr) { return attr.attributable_type === "PersonalRecord"; });
            };
            return [2 /*return*/, processingFetch
                    ? processingFetch.then(filter)
                    : filter(currentAttributes)];
        });
    }); },
    fetchClubAttributes: function () { return __awaiter(void 0, void 0, Promise, function () {
        var filter;
        return __generator(this, function (_a) {
            filter = function (attrs) {
                return attrs.filter(function (attr) { return attr.attributable_type === "ClubRecord"; });
            };
            return [2 /*return*/, processingFetch
                    ? processingFetch.then(filter)
                    : filter(currentAttributes)];
        });
    }); },
    create: function (customAttributeDefinition) { return __awaiter(void 0, void 0, Promise, function () {
        var response;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, api.createCustomAttributeDefinition((_a = currentContext.client_id) === null || _a === void 0 ? void 0 : _a.toString(), (_b = currentContext.level_id) === null || _b === void 0 ? void 0 : _b.toString(), customAttributeDefinition)];
                case 1:
                    response = _c.sent();
                    currentAttributes.push(response.data);
                    return [2 /*return*/, response.data];
            }
        });
    }); },
    update: function (customAttributeDefinition) { return __awaiter(void 0, void 0, Promise, function () {
        var response, index, error_1, _error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.updateCustomAttributeDefinition(customAttributeDefinition.id.toString(), __assign(__assign({}, customAttributeDefinition), { send_to_front: false, send_to_back: false }))];
                case 1:
                    response = _a.sent();
                    index = currentAttributes.findIndex(function (attr) { return attr.id === customAttributeDefinition.id; });
                    currentAttributes[index] = response.data;
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    _error = error_1;
                    if (_error.response.status === 424) {
                        return [2 /*return*/, parseUpdateErrors(_error.response.data)];
                    }
                    if (_error.response.status === 423) {
                        throw new LockedException(_error.response.data);
                    }
                    throw error_1;
                case 3: return [2 /*return*/];
            }
        });
    }); },
    delete: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.deleteCustomAttributeDefinition(id.toString())];
                case 1:
                    _a.sent();
                    currentAttributes = currentAttributes.filter(function (attr) { return attr.id !== id; });
                    return [2 /*return*/];
            }
        });
    }); },
    batchUpdate: function (id, file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, api.batchUpdateCustomAttributeValues(id.toString(), file)];
        });
    }); },
    move: function (id, attributable_type) { return __awaiter(void 0, void 0, Promise, function () {
        var response, index, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.updateCustomAttributeDefinition(id.toString(), __assign(__assign({}, currentAttributes.find(function (attr) { return attr.id === id; })), { attributable_type: attributable_type, send_to_front: false, send_to_back: false }))];
                case 1:
                    response = _a.sent();
                    index = currentAttributes.findIndex(function (attr) { return attr.id === id; });
                    currentAttributes[index] = response.data;
                    return [2 /*return*/, response.data];
                case 2:
                    error_2 = _a.sent();
                    if (error_2.response.status === 423) {
                        throw new LockedException(error_2.response.data);
                    }
                    throw error_2;
                case 3: return [2 /*return*/];
            }
        });
    }); },
    /**
     * Takes the ID of the attribute that should be moved and the ID of the target attribute (the attribute which place should be taken by the moved attribute).
     * Returns a list of all attributes in the new order.
     * If the moved and target attribute are both PersonalRecords-Attributes only PersonalRecords-Attributes are returned.
     * If the moved and target attribute are both ClubRecords-Attributes only ClubRecords-Attributes are returned.
     * If the moved and target attribute are of different types all attributes are returned.
     */
    reorder: function (movedId, targetId) { return __awaiter(void 0, void 0, Promise, function () {
        var moved_1, target, response, updatedMoved, movedIndexArray, targetIndexArray, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    moved_1 = currentAttributes.find(function (attr) { return attr.id === movedId; });
                    target = currentAttributes.find(function (attr) { return attr.id === targetId; });
                    return [4 /*yield*/, api.updateCustomAttributeDefinition(movedId.toString(), __assign(__assign({}, moved_1), { index: target.index, send_to_front: false, send_to_back: false }))];
                case 1:
                    response = _a.sent();
                    updatedMoved = response.data;
                    movedIndexArray = currentAttributes.indexOf(moved_1);
                    targetIndexArray = currentAttributes.indexOf(target);
                    currentAttributes.splice(movedIndexArray, 1);
                    currentAttributes.splice(targetIndexArray, 0, updatedMoved);
                    if (moved_1.attributable_type === target.attributable_type) {
                        return [2 /*return*/, currentAttributes.filter(function (attr) { return attr.attributable_type === moved_1.attributable_type; })];
                    }
                    return [2 /*return*/, currentAttributes];
                case 2:
                    error_3 = _a.sent();
                    if (error_3.response.status === 423) {
                        throw new LockedException(error_3.response.data);
                    }
                    throw error_3;
                case 3: return [2 /*return*/];
            }
        });
    }); },
    fetchProgress: function (ids) { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, Promise.all(ids.map(function (id) { return api.getCustomAttributeDefinition(id.toString()); })).then(function (responses) {
                    responses.forEach(function (response) {
                        var index = currentAttributes.findIndex(function (attr) { return attr.id === response.data.id; });
                        currentAttributes[index] = response.data;
                    });
                    return responses.map(function (response) { return ({
                        update_in_progress: response.data.update_in_progress,
                        id: response.data.id,
                    }); });
                })];
        });
    }); },
};
export { CustomAttributesClient, overrideCustomAttributesApi };
