import { __awaiter, __generator, __spreadArray } from "tslib";
import { computed, ref } from "vue";
import "suus-api";
import { rsvpApi } from "store/api";
export function Declines(eventId, RSVP_LOAD_LIMIT) {
    /**
     * COUNTS
     */
    var declinesCount = ref(0);
    var updateDeclinesCount = function (count) {
        declinesCount.value = count;
    };
    /**
     * EXPECTED COUNTS
     */
    var expectedCounter = ref(0);
    var totalDeclinesCount = computed(function () { return declinesCount.value + expectedCounter.value; });
    function resetExpectedDeclinesCount() {
        expectedCounter.value = 0;
    }
    function incrementExpectedDeclinesCount() {
        expectedCounter.value += 1;
    }
    /**
     * LOADING
     */
    var declines = ref([]);
    var declinesOffset = computed(function () { return declines.value.length; });
    var allDeclinesLoaded = ref(false);
    function updateAllDeclinesLoaded(definitelyAllLoaded, newlyLoadedCount) {
        allDeclinesLoaded.value =
            definitelyAllLoaded ||
                newlyLoadedCount < RSVP_LOAD_LIMIT ||
                declines.value.length >= declinesCount.value + expectedCounter.value;
    }
    function loadFirstDeclines() {
        return __awaiter(this, void 0, void 0, function () {
            var _declinedAttendeesResponse, _declinedAttendees;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, rsvpApi.getRsvps(eventId, 0, RSVP_LOAD_LIMIT, undefined, "decline")];
                    case 1:
                        _declinedAttendeesResponse = _a.sent();
                        _declinedAttendees = _declinedAttendeesResponse.data;
                        // Set Declined Attendees
                        declines.value = _declinedAttendees;
                        resetExpectedDeclinesCount();
                        updateAllDeclinesLoaded(_declinedAttendeesResponse.status === 204, _declinedAttendees.length);
                        return [2 /*return*/];
                }
            });
        });
    }
    function loadMoreDeclines() {
        return __awaiter(this, void 0, void 0, function () {
            var rsvpResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (allDeclinesLoaded.value)
                            return [2 /*return*/];
                        return [4 /*yield*/, rsvpApi.getRsvps(eventId, declinesOffset.value, RSVP_LOAD_LIMIT, undefined, "decline")];
                    case 1:
                        rsvpResponse = _a.sent();
                        addDeclines(rsvpResponse.data);
                        updateAllDeclinesLoaded(rsvpResponse.status === 204, rsvpResponse.data.length);
                        return [2 /*return*/];
                }
            });
        });
    }
    function addDeclines(_declines) {
        declines.value = __spreadArray(__spreadArray([], _declines, true), declines.value, true);
    }
    return {
        // Counts
        updateDeclinesCount: updateDeclinesCount,
        // Expected Counts
        totalDeclinesCount: totalDeclinesCount,
        incrementExpectedDeclinesCount: incrementExpectedDeclinesCount,
        // Loading
        declines: declines,
        loadFirstDeclines: loadFirstDeclines,
        loadMoreDeclines: loadMoreDeclines,
        addDeclines: addDeclines,
        allDeclinesLoaded: allDeclinesLoaded,
    };
}
