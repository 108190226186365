import { defineComponent } from "vue";
import THEME from "constants/theme";
import "app/ui-primitives/menus/popup-menu/PopupMenu.vue";
export default defineComponent({
    name: "MultiMenuItem",
    props: {
        title: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        clickHandler: {
            type: Function,
            required: false,
            default: function () {
                //pass
            },
        },
        path: {
            type: String,
            required: false,
        },
    },
    setup: function (props) {
        var _a = THEME.typography, fontFamily = _a.fontFamily, fontWeightRegular = _a.fontWeightRegular, rootFontSize = _a.rootFontSize;
        var textColor = THEME.colors.light.textColor;
        var itemClickHandler = function (item) { return function () {
            var _a;
            if (item.path) {
                window.location.href = item.path;
            }
            else {
                (_a = item.clickHandler) === null || _a === void 0 ? void 0 : _a.call(item);
            }
        }; };
        return {
            itemClickHandler: itemClickHandler,
            listItemStyles: {
                fontFamily: fontFamily,
                fontSize: "".concat(0.9 * rootFontSize, "px"),
                fontWeight: fontWeightRegular,
                color: textColor,
            },
        };
    },
});
