import { defineComponent, ref } from "vue";
import "features/gallery/lightbox/lightbox.service";
import CloseButton from "features/common/ui/CloseButton.vue";
export default defineComponent({
    name: "Lightbox",
    components: { CloseButton: CloseButton },
    props: {
        service: {
            type: Object,
            required: true,
        },
    },
    setup: function (props) {
        var overlay = ref(false);
        var image = ref(null);
        var handleOpen = function (imageToOpen) {
            image.value = imageToOpen;
            overlay.value = true;
        };
        props.service.registerCallback(handleOpen);
        var close = function () {
            image.value = null;
            overlay.value = false;
        };
        return {
            overlay: overlay,
            close: close,
            image: image,
        };
    },
});
