var _a, _b, _c;
import { CustomAttributeReadAccessEnum, CustomAttributeTypeEnum, CustomAttributeWriteAccessEnum, } from "suus-api";
import { mdiAccountCog, mdiAccountEdit, mdiAccountMultiple, mdiCalendar, mdiCheckboxMarked, mdiDecimalComma, mdiFormatTitle, mdiFormDropdown, mdiNumeric, mdiShieldAccount, mdiText, } from "@mdi/js";
export var CUSTOM_ATTRIBUTE_TYPE_ICONS = (_a = {},
    _a[CustomAttributeTypeEnum.Integer] = mdiNumeric,
    _a[CustomAttributeTypeEnum.Float] = mdiDecimalComma,
    _a[CustomAttributeTypeEnum.String] = mdiFormatTitle,
    _a[CustomAttributeTypeEnum.Text] = mdiText,
    _a[CustomAttributeTypeEnum.Boolean] = mdiCheckboxMarked,
    _a[CustomAttributeTypeEnum.Date] = mdiCalendar,
    _a[CustomAttributeTypeEnum.FuzzyDate] = mdiCalendar,
    _a[CustomAttributeTypeEnum.Select] = mdiFormDropdown,
    _a);
export var CUSTOM_ATTRIBUTE_READ_ACCESS_ICONS = (_b = {},
    _b[CustomAttributeReadAccessEnum.Admins] = {
        icon: mdiShieldAccount,
        tooltip: "Nur Admins können dieses Feld sehen.",
    },
    _b[CustomAttributeReadAccessEnum.Managers] = {
        icon: mdiAccountCog,
        tooltip: "Nur Adressverwalter und Admins können dieses Feld sehen.",
    },
    _b[CustomAttributeReadAccessEnum.Editors] = {
        icon: mdiAccountEdit,
        tooltip: "Nur Adressverwalter, Admins und Profilbesitzer können dieses Feld sehen.",
    },
    _b[CustomAttributeReadAccessEnum.Viewers] = {
        icon: mdiAccountMultiple,
        tooltip: "Alle können dieses Feld sehen.",
    },
    _b);
export var CUSTOM_ATTRIBUTE_WRITE_ACCESS_ICONS = (_c = {},
    _c[CustomAttributeWriteAccessEnum.Admins] = {
        icon: mdiShieldAccount,
        tooltip: "Nur Admins können dieses Feld bearbeiten.",
    },
    _c[CustomAttributeWriteAccessEnum.Managers] = {
        icon: mdiAccountCog,
        tooltip: "Nur Adressverwalter und Admins können dieses Feld bearbeiten.",
    },
    _c[CustomAttributeWriteAccessEnum.Editors] = {
        icon: mdiAccountEdit,
        tooltip: "Adressverwalter, Admins und Profilbesitzer können dieses Feld bearbeiten.",
    },
    _c);
