<template lang="pug">
v-container
    v-row(v-if="loading")
        v-col(cols="12", v-for="index in 3", :key="index")
            v-skeleton-loader(type="sentences")
    v-row(v-if="hasDescription && !loading")
        v-col.d-flex.justify-md-center(cols="12", sm="2", v-if="!isXsScreen()")
            QuoteIcon(:width="34")
        v-col(cols="12", sm="7")
            span.pr-8(v-if="isXsScreen()")
                QuoteIcon(:width="34") 
            RenderedWysiwygContent(:html="currentEvent.description" :attachments="attachments" customClass="body-1" style="overflow-x:auto;")
    v-row(align="center", v-if="!hasDescription && !loading")
        v-col(cols="12")
            TextBody(customClass="text-center mb-4", :text="noDescriptionText")
</template>

<script>
import QuoteIcon from "../icons/QuoteIcon.vue"
import TextBody from "../UI/TextBody.vue"
import TextHTML from "../UI/TextHTML.vue"
import TextButton from "../UI/TextButton.vue"
import { createNamespacedHelpers } from "vuex"
import AttachmentList from "components/AttachmentList.vue"
import Header from "components/UI/Header.vue"
import { mdiPencilOutline } from "@mdi/js"
import useTranslate from "features/common/translate/concerns/useTranslate"
import { useLightbox } from "features/gallery/lightbox/lightbox.service"
import RenderedWysiwygContent from "components/Wysiwyg/RenderedWysiwygContent.vue"
import { useOwnUserAttendanceContext } from "app/modules/events/use-own-user-attendance-context.concern"
const { mapState, mapGetters } = createNamespacedHelpers("event")

export default {
  name: "EventDescription",
  components: {
    RenderedWysiwygContent,
    Header,
    QuoteIcon,
    TextBody,
    TextButton,
    TextHTML,
    AttachmentList,
  },
  props: {
    openEditFunction: {
      type: Function,
      required: true,
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isXsScreen() {
      return this.$vuetify.breakpoint.name === "xs"
    },
  },
  computed: {
    loading() {
      return this.eventLoading || this.ownUserStatusLoading
    },
    hasDescription() {
      return !!this.currentEvent && !!this.currentEvent.description
    },
    noDescriptionText() {
      return this.$t("components.event_description.no_description")
    },
    attachments() {
      return this.currentEvent.attachments
    },
    ...mapState({
      eventLoading: (state) => state.currentEvent.loading,
    }),
    ...mapGetters(["currentEvent", "currentEventAttachmentsOptimisticCount"]),
  },
  setup() {
    const { t$ } = useTranslate("attachments")

    const lightbox = useLightbox()

    const openLightbox = (image) => {
      lightbox.openLightbox(image)
    }

    const { ownUserStatusLoading } = useOwnUserAttendanceContext()

    return {
      t$,
      openLightbox,
      ownUserStatusLoading,
    }
  },
}
</script>
