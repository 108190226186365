import { __assign, __awaiter, __generator } from "tslib";
import { ref, computed, defineComponent, watch, } from "vue";
import THEME from "../../constants/theme";
import "suus-api";
import TextButton from "../UI/TextButton.vue";
import Comment from "./Comment.vue";
import { mdiChevronDown } from "@mdi/js";
import "concerns/useContextName";
import OmissionLoader from "features/omission-loader/OmissionLoader.vue";
import "features/omission-loader/use-omission-loader-internal";
import CommentLoading from "components/Comment/CommentLoading.vue";
export default defineComponent({
    name: "CommentList",
    components: { CommentLoading: CommentLoading, OmissionLoader: OmissionLoader, Comment: Comment, TextButton: TextButton },
    props: {
        viewContext: { type: Object },
        comments: {
            type: Array,
            default: function () { return []; },
        },
        loading: { type: Boolean, default: false },
        updateComment: { type: Function },
        deleteComment: { type: Function },
    },
    setup: function (props) {
        var _this = this;
        var omissionLoader = ref(null);
        var comments = ref(props.comments);
        watch(props, function (props) { return (comments.value = props.comments); });
        function deleteComment(comment) {
            var _a;
            return __awaiter(this, void 0, Promise, function () {
                var deletedCommentId;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, props.deleteComment(comment)];
                        case 1:
                            deletedCommentId = _b.sent();
                            (_a = omissionLoader.value) === null || _a === void 0 ? void 0 : _a.removeItem(deletedCommentId);
                            return [2 /*return*/];
                    }
                });
            });
        }
        var updateComment = function (comment) { return __awaiter(_this, void 0, void 0, function () {
            var updatedComment;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, props.updateComment(comment)];
                    case 1:
                        updatedComment = _b.sent();
                        (_a = omissionLoader.value) === null || _a === void 0 ? void 0 : _a.updateItem(comment.id, function () { return updatedComment; });
                        return [2 /*return*/];
                }
            });
        }); };
        var commentsCountText = computed(function () {
            return comments.value.length === 1 ? "Kommentar" : "Kommentare";
        });
        var _a = THEME.typography, hyperlink = _a.hyperlink, rootFontSize = _a.rootFontSize;
        var textColor = THEME.colors.light.textColor;
        var loadMore = function (offset, pageSize) {
            var start = offset;
            var index = offset + pageSize;
            var cappedIndex = index > comments.value.length ? comments.value.length : index;
            return Promise.resolve(comments.value.slice(start, cappedIndex));
        };
        var add = function (comment) {
            var _a;
            (_a = omissionLoader.value) === null || _a === void 0 ? void 0 : _a.addItemToLeading(comment);
            comments.value.unshift(comment);
        };
        return {
            omissionLoader: omissionLoader,
            comments: comments,
            saveComment: updateComment,
            deleteComment: deleteComment,
            commentCountStyles: __assign(__assign({}, hyperlink), { fontSize: "".concat(rootFontSize * 0.8, "px"), lineHeight: "".concat(rootFontSize * 0.8 * 1.5, "px"), color: textColor }),
            commentsCountText: commentsCountText,
            panelIcon: mdiChevronDown,
            loadMore: loadMore,
            add: add,
        };
    },
});
