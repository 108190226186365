import { __assign, __awaiter, __generator } from "tslib";
import { defineComponent, nextTick, ref } from "vue";
import CustomAttributesUpdateErrors from "app/modules/custom-attributes/CustomAttributesUpdateErrors.vue";
import Button from "components/UI/Button.vue";
import CustomAttributesForm from "app/modules/custom-attributes/form/CustomAttributesForm.vue";
import { mdiClose } from "@mdi/js";
import { CustomAttributesClient, LockedException, } from "app/modules/custom-attributes/client";
import "suus-api";
import TextButton from "components/UI/TextButton.vue";
import { useIsMobile } from "features/common/vuetify/useVuetify";
import SuccessSnackbar from "app/ui-primitives/snackbars/SuccessSnackbar.vue";
import ErrorText from "app/ui-primitives/text/ErrorText.vue";
import { useSnackbar } from "app/ui-primitives/snackbars/SnackbarProvider.vue";
export default defineComponent({
    name: "CustomAttributesEditDialog",
    components: {
        ErrorText: ErrorText,
        SuccessSnackbar: SuccessSnackbar,
        TextButton: TextButton,
        CustomAttributesForm: CustomAttributesForm,
        Button: Button,
        CustomAttributesUpdateErrors: CustomAttributesUpdateErrors,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        customAttribute: {
            type: Object,
            required: true,
        },
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var loading = ref(false);
        var serverError = ref(null);
        var emitDialogState = function (state) {
            emit("input", state);
        };
        var closeDialog = function () {
            updateErrors.value = [];
            emitDialogState(false);
        };
        var updateErrors = ref([]);
        var updateErrorsContainer = ref(null);
        var scrollToErrors = function () {
            if (updateErrorsContainer.value) {
                updateErrorsContainer.value.scrollIntoView({ behavior: "smooth" });
            }
        };
        var showSuccess = useSnackbar().showSuccess;
        var handleUpdate = function (customAttribute) { return __awaiter(_this, void 0, void 0, function () {
            var updatedAttributeOrUpdateErrors, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        updateErrors.value = [];
                        serverError.value = null;
                        loading.value = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, CustomAttributesClient.update(customAttribute)
                            // If the update returns an array, it contains errors.
                        ];
                    case 2:
                        updatedAttributeOrUpdateErrors = _b.sent();
                        // If the update returns an array, it contains errors.
                        if (updatedAttributeOrUpdateErrors instanceof Array) {
                            updateErrors.value = updatedAttributeOrUpdateErrors;
                            nextTick().then(scrollToErrors);
                        }
                        else {
                            (_a = customAttributesForm.value) === null || _a === void 0 ? void 0 : _a.resetForm();
                            closeDialog();
                            showSuccess("Das Feld wurde erfolgreich aktualisiert.");
                            emit("updated", updatedAttributeOrUpdateErrors);
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        if (error_1 instanceof LockedException) {
                            emit("updated", __assign(__assign({}, props.customAttribute), { update_in_progress: true }));
                            serverError.value =
                                "Es befindet sich bereits ein Update in Bearbeitung. Versuche es später erneut.";
                            console.error(error_1);
                            return [2 /*return*/];
                        }
                        serverError.value =
                            "Es gab einen Fehler beim Aktualisieren des Attributs.";
                        console.error(error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        loading.value = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var customAttributesForm = ref(null);
        var enableSave = ref(false);
        var setEnableSave = function (value) {
            enableSave.value = value;
        };
        var submitForm = function () {
            var _a;
            (_a = customAttributesForm.value) === null || _a === void 0 ? void 0 : _a.submitForm();
        };
        var cancelForm = function () {
            var _a;
            (_a = customAttributesForm.value) === null || _a === void 0 ? void 0 : _a.resetForm();
            closeDialog();
        };
        var isMobile = useIsMobile();
        return {
            mdiClose: mdiClose,
            emitDialogState: emitDialogState,
            closeDialog: closeDialog,
            handleUpdate: handleUpdate,
            updateErrors: updateErrors,
            updateErrorsContainer: updateErrorsContainer,
            customAttributesForm: customAttributesForm,
            enableSave: enableSave,
            setEnableSave: setEnableSave,
            submitForm: submitForm,
            cancelForm: cancelForm,
            isMobile: isMobile,
            loading: loading,
            serverError: serverError,
        };
    },
});
