import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

const state = {
  events: [],
  currentEvent: {
    data: null,
    error: null,
    loading: false,
  },
  eventForm: {},
  attendees: [],
  activePanels: [0],
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
