import Vue from "vue";
import TextHTML from "../UI/TextHTML.vue";
import Subtitle from "../UI/Subtitle.vue";
import RelativeTime from "../UI/RelativeTime.vue";
import Avatar from "../UI/Avatar.vue";
import "interfaces/menuItem.interface";
import PopupMenu from "../../app/ui-primitives/menus/popup-menu/PopupMenu.vue";
import { goToUrl } from "helpers/navigation";
import "suus-api";
import "vue";
import { getContextNameLong } from "concerns/useContextName";
export default Vue.extend({
    name: "CommentBlock",
    components: {
        Subtitle: Subtitle,
        RelativeTime: RelativeTime,
        TextHTML: TextHTML,
        Avatar: Avatar,
        PopupMenu: PopupMenu,
    },
    props: {
        viewContext: { type: Object },
        createdAt: { type: [String, Date], required: true },
        text: { type: String, required: true },
        creator: { type: Object, required: true },
        menuItems: {
            type: Array,
            default: function () { return []; },
            required: true,
        },
        isEdit: { type: Boolean, default: false },
    },
    setup: function () {
        return { getContextNameLong: getContextNameLong };
    },
    computed: {
        isXsScreenSize: function () {
            return this.$vuetify.breakpoint.name === "xs";
        },
        variant: function () {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "xs";
                default:
                    return "sm";
            }
        },
    },
    methods: {
        goToProfile: function () {
            if (this.creator.url)
                goToUrl(this.creator.url);
        },
    },
});
