import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import "suus-api";
import Button from "components/UI/Button.vue";
import Header2 from "app/ui-primitives/header/Header2.vue";
import Sortable from "sortablejs";
import CustomAttributesListItem from "app/modules/custom-attributes/CustomAttributesListItem.vue";
import { useSnackbar } from "app/ui-primitives/snackbars/SnackbarProvider.vue";
import { CustomAttributesClient, LockedException, } from "app/modules/custom-attributes/client";
export default defineComponent({
    name: "CustomAttributesList",
    components: {
        CustomAttributesListItem: CustomAttributesListItem,
        Header2: Header2,
        Button: Button,
    },
    props: {
        attributableType: {
            type: String,
            required: true,
        },
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var loading = ref(true);
        var attributes = ref([]);
        var fetchFunction = props.attributableType === "PersonalRecord"
            ? CustomAttributesClient.fetchPersonalAttributes
            : CustomAttributesClient.fetchClubAttributes;
        fetchFunction()
            .then(function (response) {
            attributes.value = response;
        })
            .catch(function (e) {
            emit("fetchError");
            console.error(e);
        })
            .finally(function () {
            loading.value = false;
        });
        var addAttribute = function (attr) {
            attributes.value = __spreadArray(__spreadArray([], attributes.value, true), [attr], false);
        };
        var showError = useSnackbar().showError;
        var listContainer = ref(null);
        onMounted(function () {
            var previousSort = null;
            var sortable = new Sortable(listContainer.value.$el, {
                sort: true,
                chosenClass: "chosen",
                filter: ".disable-sort",
                dataIdAttr: "data-id",
                onStart: function (_) {
                    previousSort = sortable.toArray();
                },
                onEnd: function (event) { return __awaiter(_this, void 0, void 0, function () {
                    var movedAttribute, targetAttribute, _a, e_1, movedAttribute_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                movedAttribute = attributes.value[event.oldIndex];
                                targetAttribute = attributes.value[event.newIndex];
                                _a = attributes;
                                return [4 /*yield*/, CustomAttributesClient.reorder(movedAttribute.id, targetAttribute.id)];
                            case 1:
                                _a.value = _b.sent();
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                if (e_1 instanceof LockedException) {
                                    movedAttribute_1 = attributes.value[event.oldIndex];
                                    attributes.value = attributes.value.map(function (attr) {
                                        if (attr.id === movedAttribute_1.id) {
                                            return __assign(__assign({}, attr), { update_in_progress: true });
                                        }
                                        return attr;
                                    });
                                    console.warn(e_1);
                                }
                                console.error(e_1);
                                sortable.sort(previousSort);
                                showError("Fehler beim Verschieben des Feldes");
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); },
            });
        });
        var isPlaceholderVisible = computed(function () { return attributes.value.length === 0; });
        var additionalContainerClasses = computed(function () {
            return {
                "dropzone-visible": attributes.value.length === 0 && !loading.value,
            };
        });
        var handleDeletion = function (id) {
            attributes.value = attributes.value.filter(function (attr) { return attr.id !== id; });
        };
        var handleMoved = function (_a) {
            var movedAttribute = _a.movedAttribute, targetList = _a.targetList;
            if (targetList === props.attributableType) {
                return;
            }
            attributes.value = __spreadArray([], attributes.value.filter(function (attr) { return attr.id !== movedAttribute.id; }), true);
            emit("moved", { movedAttribute: movedAttribute, targetList: targetList });
        };
        var handleUpdate = function (updatedAttr) {
            var copy = __spreadArray([], attributes.value, true);
            var indexOfUpdatedAttr = copy.findIndex(function (attr) { return attr.id === updatedAttr.id; });
            copy[indexOfUpdatedAttr] = updatedAttr;
            attributes.value = copy;
        };
        var isAnyInProgcess = computed(function () {
            return attributes.value.some(function (attr) { return attr.update_in_progress; });
        });
        var interval = null;
        watch(function () { return isAnyInProgcess.value; }, function (newValue) {
            if (newValue) {
                interval = setInterval(function () {
                    CustomAttributesClient.fetchProgress(attributes.value
                        .filter(function (attr) { return attr.update_in_progress; })
                        .map(function (attr) { return attr.id; })).then(function (response) {
                        attributes.value = attributes.value.map(function (attr) {
                            var updated = response.find(function (r) { return r.id === attr.id; });
                            return __assign(__assign({}, attr), { update_in_progress: updated
                                    ? updated.update_in_progress
                                    : false });
                        });
                    });
                }, 5000);
            }
            else {
                clearInterval(interval);
            }
        }, {
            immediate: true,
        });
        var handleUpdateInProgress = function (id) {
            attributes.value = attributes.value.map(function (attr) {
                if (attr.id === id) {
                    return __assign(__assign({}, attr), { update_in_progress: true });
                }
                return attr;
            });
        };
        return {
            listContainer: listContainer,
            additionalContainerClasses: additionalContainerClasses,
            isPlaceholderVisible: isPlaceholderVisible,
            handleDeletion: handleDeletion,
            loading: loading,
            attributes: attributes,
            addAttribute: addAttribute,
            handleMoved: handleMoved,
            handleUpdate: handleUpdate,
            isAnyInProgcess: isAnyInProgcess,
            handleUpdateInProgress: handleUpdateInProgress,
        };
    },
});
