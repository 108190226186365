import { defineComponent, provide } from "vue";
import { LightboxService } from "features/gallery/lightbox/lightbox.service";
import Lightbox from "features/gallery/lightbox/Lightbox.vue";
export default defineComponent({
    name: "LightBoxProvider",
    components: { Lightbox: Lightbox },
    setup: function () {
        var lightboxService = new LightboxService();
        provide("lightboxService", lightboxService);
        return {
            lightboxService: lightboxService,
        };
    },
});
