import { __awaiter, __generator } from "tslib";
import { computed, ref } from "vue";
import "features/common/shared/polymorphic";
/**
 *  The trailing offset is on the last loaded item position. The leading offset is on the first not-loaded item position.
 *  Example (* = loaded) :
 *  0*
 *  1*
 *  2*
 *  3   <- leading offset
 *  4
 *  5*  <- trailing offset
 *  6*
 *  7*
 *
 *  To calculate the left items, we can subtract the leading offset from the trailing offset.
 *  Example => 5 - 3 = 2
 */
export var useOmissionLoaderInternal = function (totalCount, pageSize, initialLeadingCount, initialTrailingCount, loadMore, emitLeading, emitTrailing) {
    var loadOnlyLeading = initialLeadingCount > 0 && initialTrailingCount === 0;
    var leadingOffset = ref(0);
    var trailingOffset = ref(totalCount);
    var leadingLoading = ref(0);
    var trailingLoading = ref(0);
    var remainingCount = computed(function () {
        return trailingOffset.value - leadingOffset.value;
    });
    var isLastPage = computed(function () {
        return remainingCount.value <= pageSize;
    });
    var allLoaded = computed(function () {
        // Add items that are currently loading to the remaining count
        return remainingCount.value <= 0;
    });
    var nextPageSize = computed(function () {
        return Math.min(pageSize, remainingCount.value, totalCount);
    });
    var loadLeading = function (pageSize) {
        if (pageSize === void 0) { pageSize = nextPageSize.value; }
        return __awaiter(void 0, void 0, void 0, function () {
            var offset, newLeadingItems;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        leadingLoading.value += pageSize;
                        offset = leadingOffset.value;
                        // Ensure that both markers never overlap
                        leadingOffset.value = Math.min(leadingOffset.value + pageSize, trailingOffset.value);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, loadMore(offset, pageSize)];
                    case 2:
                        newLeadingItems = _a.sent();
                        emitLeading(newLeadingItems);
                        return [3 /*break*/, 4];
                    case 3:
                        leadingLoading.value -= pageSize;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    var loadRemaining = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, loadLeading(remainingCount.value)];
        });
    }); };
    var loadTrailing = function (_pageSize) {
        if (_pageSize === void 0) { _pageSize = nextPageSize.value; }
        return __awaiter(void 0, void 0, void 0, function () {
            var offset, newTrailingItems;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (_pageSize === 0)
                            return [2 /*return*/];
                        trailingLoading.value += _pageSize;
                        offset = trailingOffset.value - _pageSize;
                        // Ensure that both markers never overlap
                        trailingOffset.value = Math.max(trailingOffset.value - _pageSize, leadingOffset.value);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, loadMore(offset, _pageSize)];
                    case 2:
                        newTrailingItems = _a.sent();
                        emitTrailing(newTrailingItems);
                        return [3 /*break*/, 4];
                    case 3:
                        trailingLoading.value -= _pageSize;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    if (totalCount > 0) {
        loadLeading(Math.min(initialLeadingCount, nextPageSize.value));
        if (!loadOnlyLeading && totalCount > initialLeadingCount) {
            loadTrailing(Math.min(initialTrailingCount, nextPageSize.value));
        }
    }
    return {
        isLastPage: isLastPage,
        loadLeading: loadLeading,
        loadTrailing: loadTrailing,
        loadRemaining: loadRemaining,
        allLoaded: allLoaded,
        leadingLoading: leadingLoading,
        trailingLoading: trailingLoading,
        remainingCount: remainingCount,
        loadOnlyLeading: loadOnlyLeading,
        nextPageSize: nextPageSize,
    };
};
