<template lang="pug">
LoaderWrapper(:requires="[currentInode]")
  template(#notFound)
    h1.ui.icon.header.centered
      span.content
        i.ui.icon.suus-ban
        | Nicht gefunden!
        .sub.header Datei nicht gefunden
  template(#default)
    Header
      template(#buttons)
        a.ui.primary.button(
          v-tooltip="$t('drive.downloadButton.tooltip')"
          v-if="currentInode.download_url"
          :href="currentInode.download_url"
        )
          i.ui.icon.suus-square-download
          span.mobile.hidden Herunterladen

        Pin(:inode="currentInode" v-if="currentInode.pin_url")

        v-popover(v-if="currentInode.public_url" trigger="hover" :hideOnTargetClick="false" :autoHide="false")
          a.ui.icon.button(@click="copyToClipboard")
            i.ui.icon.suus-world
          template(slot="popover")
            p {{ $t('drive.shareButton.tooltip') }}
            sui-form-field
              sui-input(type="text" :value="currentInode.public_url" ref="publicUrlInput")

        router-link.ui.icon.button(
          v-tooltip="$t('drive.editInodeButton.tooltip', {type: $t('drive.inode_types.' + currentInode.type.toLowerCase())} )"
          v-if="currentInode.update_url"
          :to="{name: 'drive/file/edit', params: { inode_id: currentInode.id} }"
        )
          i.ui.icon.suus-settings-tool-67

    // @see https://github.com/mozilla/pdf.js/wiki/Setup-PDF.js-in-a-website#from-examples
    iframe.pdf-wrapper(
      v-if="currentInode.content_type === 'application/pdf'"
      :src="`/pdf-js/web/viewer.html?file=${currentInode.download_url}${hashParams()}`"
    )

    video(v-else-if="currentInode.content_type.startsWith('video/')" controls width="100%")
      source(:src="currentInode.download_url" type="video/mp4")
      | Your browser does not support the video tag.

    div(v-else-if="currentInode.content_type.startsWith('audio/')" style="width: 100%; display: flex; justify-content: center;padding:64px")
      audio(controls)
        source(:src="currentInode.download_url" type="audio/mpeg")
        | Your browser does not support the audio tag.

    p.text-center(v-else-if="currentInode.preview && currentInode.preview.url")
      img.loading(
        :src="currentInode.preview.url"
        :alt="currentInode.download_name"
        :width="currentInode.preview.width"
        :height="currentInode.preview.height"
      )

    .ui.segment(v-else)
      .ui.message.text-center
        .header Keine Vorschau verfügbar
        p.text-center
          | Für dieses Dateiformat ist keine Vorschau verfügbar, du musst sie herunterladen, um sie ansehen zu können.
          br
          | Für Bilder und PDF-Dateien sind Vorschauen verfügbar.
    br
    div.text-center
      a.ui.button.primary(:href="currentInode.download_url")
        i.ui.icon.suus-cloud-download-93.large
        | Datei Herunterladen
      br
      small {{ currentInode.download_name}} ({{ currentInode.file_size | filesize }})
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex"
import Header from "../Header.vue"

import LoaderWrapper from "../LoaderWrapper.vue"
import Pin from "../Pin.vue"
export default {
  name: "DriveFile",
  data() {
    return {
      progress: 0,
      pages: undefined,
      loadedPages: 0,
      loaded: false,
      error: false,
      pageCount: 0,
    }
  },
  components: { Pin, LoaderWrapper, Header },
  computed: {
    ...mapState("drive", ["path"]),
    ...mapState("loader", ["loading"]),
    ...mapGetters("drive", ["currentInode", "parentFolder"]),
  },
  methods: {
    setError(e) {
      this.error = e
      this.loaded = false
    },
    hashParams() {
      return window.location.hash
    },
    updatePageCount(pageCount) {
      this.pageCount = pageCount
    },
    onDocumentErrored(e) {
      this.error = e
    },
    copyToClipboard() {
      const copyText =
        this.$refs.publicUrlInput.$el.getElementsByTagName("input")[0]
      console.log(copyText)
      copyText.select()
      document.execCommand("copy")
    },
  },
}
</script>

<style scoped lang="sass">
.pdf-wrapper
  width: 100%
  height: 80vh
</style>
