import { computed, defineComponent, onMounted, ref } from "vue";
import InfiniteScrollLoader from "features/common/pagination-and-loader/InfiniteScrollLoader.vue";
export default defineComponent({
    name: "GalleryGridUI",
    components: { InfiniteScrollLoader: InfiniteScrollLoader },
    props: {
        totalCount: {
            type: Number,
            required: true,
        },
        loadedItems: {
            type: Array,
            required: true,
            default: function () { return []; },
        },
        loadingCount: {
            type: Number,
            required: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var grid = ref(null);
        var isAllLoaded = computed(function () { return props.loadedItems.length + props.loadingCount >= props.totalCount; });
        var loadMore = function () {
            emit("load-more");
        };
        var gridStyles = computed(function () {
            return {
                gridTemplateColumns: props.compact
                    ? "1fr 1fr 1fr"
                    : "repeat(auto-fill, 128px)",
                gridGap: props.compact ? "4px" : "16px",
            };
        });
        onMounted(function () {
            if (grid.value) {
                emit("grid-mounted", grid.value);
            }
        });
        return {
            isAllLoaded: isAllLoaded,
            loadMore: loadMore,
            gridStyles: gridStyles,
            grid: grid,
        };
    },
});
