import { __assign } from "tslib";
import { defineComponent, ref, watch } from "vue";
import { EVENT_STATUS } from "constants/constants";
import { createNamespacedHelpers } from "vuex";
var mapState = createNamespacedHelpers("event").mapState;
import Header from "../UI/Header.vue";
import Subtitle from "../UI/Subtitle.vue";
import TextBody from "../UI/TextBody.vue";
import ConfettiBag from "../graphics/ConfettiBag.vue";
import { mdiClose } from "@mdi/js";
import { mdiMinus } from "@mdi/js";
import { mdiPlus } from "@mdi/js";
import "suus-api";
import GuestAmountSelection from "components/Event/GuestAmountSelection.vue";
import { useOwnUserAttendanceContext } from "app/modules/events/use-own-user-attendance-context.concern";
import { useRSVPContextOptional } from "app/modules/events/rsvp-context/use-rsvp-context.concern";
export default defineComponent({
    name: "GuestModal",
    props: { guest_count: { type: Number, required: true } },
    components: {
        GuestAmountSelection: GuestAmountSelection,
        Header: Header,
        TextBody: TextBody,
        Subtitle: Subtitle,
        ConfettiBag: ConfettiBag,
    },
    data: function () {
        return {
            showModal: false,
            closeIcon: mdiClose,
            minusIcon: mdiMinus,
            plusIcon: mdiPlus,
            isMobile: this.$vuetify.breakpoint.mobile,
            disableSubmit: false,
            validationPattern: /^[0-9]{1,2}$/,
        };
    },
    methods: {
        add: function () {
            this.guests = this.guests + 1;
        },
        subtract: function () {
            if (this.guests > 0) {
                this.guests = this.guests - 1;
            }
        },
        close: function () {
            this.$emit("close", this.guests);
        },
        onSubmit: function () {
            var _this = this;
            this.close();
            this.setEventAttendStatusDirectly(EVENT_STATUS.FINISHED_REGISTRATION);
            if (this.$route.name === "Event") {
                this.attend(this.guests).then(function () {
                    if (_this.reloadAttendees)
                        _this.reloadAttendees();
                });
            }
            if (this.$route.name === "Attend") {
                this.attendFromDigest(this.guests);
            }
        },
    },
    computed: __assign({ rules: function () {
            var _this = this;
            return {
                required: function (value) {
                    if (value === 0 || !!value) {
                        return true;
                    }
                    return _this.$i18n.t("components.guest_modal.rules.rule1");
                },
                guests: function (value) {
                    if (value > 20) {
                        return _this.$i18n.t("components.guest_modal.rules.rule2");
                    }
                    return (_this.validationPattern.test(value) ||
                        _this.$i18n.t("components.guest_modal.rules.rule3"));
                },
            };
        }, modalContent: function () {
            return {
                headerText: this.$i18n
                    .t("components.guest_modal.headerText")
                    .toString(),
                subtitleText: this.$i18n
                    .t("components.guest_modal.subtitleText")
                    .toString(),
                bodyText: this.$i18n.t("components.guest_modal.bodyText").toString(),
            };
        }, graphicWidth: function () {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "180";
                default:
                    return "250";
            }
        } }, mapState({
        currentEvent: function (state) { return state.currentEvent.data; },
    })),
    watch: {
        guests: function (val) {
            if (val === undefined ||
                val === null ||
                val > 20 ||
                !this.validationPattern.test(val.toString())) {
                this.disableSubmit = true;
                return;
            }
            this.disableSubmit = false;
        },
    },
    setup: function (props) {
        var _a;
        var _b = useOwnUserAttendanceContext(), setEventAttendStatusDirectly = _b.setEventAttendStatusDirectly, attend = _b.attend, attendFromDigest = _b.attendFromDigest, ownUserGuestsCount = _b.ownUserGuestsCount;
        var guests = ref(props.guest_count || 0);
        watch(function () { return ownUserGuestsCount.value; }, function (val) {
            guests.value = val;
        }, { immediate: true });
        var rsvpContextOptional = useRSVPContextOptional();
        var reloadAttendees = (_a = rsvpContextOptional === null || rsvpContextOptional === void 0 ? void 0 : rsvpContextOptional.reloadRSVPs) !== null && _a !== void 0 ? _a : null;
        return {
            setEventAttendStatusDirectly: setEventAttendStatusDirectly,
            attend: attend,
            attendFromDigest: attendFromDigest,
            guests: guests,
            reloadAttendees: reloadAttendees,
        };
    },
});
