import { defineComponent, ref } from "vue";
import { useOmissionLoaderInternal, } from "features/omission-loader/use-omission-loader-internal";
import DefaultSkeletonLoader from "ui-primitives/loading-indicators/DefaultSkeletonLoader.vue";
import "features/common/shared/polymorphic";
import TextButton from "components/UI/TextButton.vue";
import { removeWhere, updateWhere } from "app/util/ref-list.util";
export default defineComponent({
    name: "OmissionLoader",
    components: {
        TextButton: TextButton,
        DefaultSkeletonLoader: DefaultSkeletonLoader,
    },
    props: {
        initialLeadingCount: {
            type: Number,
            required: true,
        },
        initialTrailingCount: {
            type: Number,
            required: true,
        },
        pageSize: {
            type: Number,
            required: true,
        },
        totalCount: {
            type: Number,
            required: true,
        },
        loadMore: {
            type: Function,
            required: true,
        },
    },
    setup: function (props, context) {
        var leadingItems = ref([]);
        var trailingItems = ref([]);
        var _a = useOmissionLoaderInternal(props.totalCount, props.pageSize, props.initialLeadingCount, props.initialTrailingCount, props.loadMore, function (items) {
            var _a;
            (_a = leadingItems.value).push.apply(_a, items);
            context.emit("leadingLoaded", items);
            emitTotalLoaded();
        }, function (items) {
            var _a;
            (_a = trailingItems.value).unshift.apply(_a, items);
            context.emit("trailingLoaded", items);
            emitTotalLoaded();
        }), isLastPage = _a.isLastPage, allLoaded = _a.allLoaded, loadOnlyLeading = _a.loadOnlyLeading, loadLeading = _a.loadLeading, loadTrailing = _a.loadTrailing, loadRemaining = _a.loadRemaining, trailingLoading = _a.trailingLoading, leadingLoading = _a.leadingLoading, remainingCount = _a.remainingCount, nextPageSize = _a.nextPageSize;
        var emitTotalLoaded = function () {
            context.emit("totalLoaded", leadingItems.value.length + trailingItems.value.length);
        };
        var addItemToLeading = function (item) {
            leadingItems.value.unshift(item);
            emitTotalLoaded();
        };
        var addItemToTrailing = function (item) {
            trailingItems.value.push(item);
            emitTotalLoaded();
        };
        var updateItem = function (id, updateFunction) {
            var isItemInLeading = leadingItems.value.some(function (i) { return i.id === id; });
            if (isItemInLeading) {
                updateWhere(leadingItems, function (i) { return i.id === id; }, function (i) { return updateFunction(i); });
            }
            else {
                updateWhere(trailingItems, function (i) { return i.id === id; }, function (i) { return updateFunction(i); });
            }
        };
        var removeItem = function (id) {
            removeWhere(leadingItems, function (i) { return i.id === id; });
            removeWhere(trailingItems, function (i) { return i.id === id; });
            emitTotalLoaded();
        };
        return {
            isLastPage: isLastPage,
            allLoaded: allLoaded,
            loadOnlyLeading: loadOnlyLeading,
            loadLeading: loadLeading,
            loadTrailing: loadTrailing,
            loadRemaining: loadRemaining,
            trailingLoading: trailingLoading,
            leadingLoading: leadingLoading,
            leadingItems: leadingItems,
            trailingItems: trailingItems,
            remainingCount: remainingCount,
            nextPageSize: nextPageSize,
            // COMPONENT METHODS
            addItemToLeading: addItemToLeading,
            addItemToTrailing: addItemToTrailing,
            updateItem: updateItem,
            removeItem: removeItem,
        };
    },
});
