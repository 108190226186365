import { defineComponent, onMounted, ref } from "vue";
import Header2 from "app/ui-primitives/header/Header2.vue";
import CustomAttributesManagement from "app/modules/custom-attributes/CustomAttributesManagement.vue";
import ConfirmationDialogProvider from "features/common/confirmation-dialog/ConfirmationDialogProvider.vue";
import { CustomAttributesClient } from "app/modules/custom-attributes/client";
export default defineComponent({
    name: "CustomAttributesManagementPortal",
    components: {
        ConfirmationDialogProvider: ConfirmationDialogProvider,
        CustomAttributesManagement: CustomAttributesManagement,
        Header2: Header2,
    },
    setup: function () {
        var render = ref(false);
        var clientId = ref(null);
        var levelId = ref(null);
        onMounted(function () {
            var _a, _b;
            var mountingPoint = document.querySelector("#custom-attributes-management");
            if (mountingPoint) {
                //mountingPoint.classList.add("v-application") // add vuetify styles
                // read the data attribute and parse it as json
                var dataRaw = mountingPoint.getAttribute("data");
                var data = dataRaw ? JSON.parse(dataRaw) : {};
                clientId.value = (_a = data.clientId) !== null && _a !== void 0 ? _a : null;
                levelId.value = (_b = data.levelId) !== null && _b !== void 0 ? _b : null;
                render.value = true;
                CustomAttributesClient.loadContext(clientId.value, levelId.value);
            }
        });
        return {
            render: render,
        };
    },
});
