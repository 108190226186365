import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, ref } from "vue";
import store from "store";
import axios from "initializers/axios";
import GalleryGridUI from "app/ui-primitives/gallery/GalleryGridUI.vue";
import { useLightbox, } from "features/gallery/lightbox/lightbox.service";
import { getContextName } from "concerns/useContextName";
import GalleryViewSkeletonLoader from "features/gallery/view/GalleryViewSkeletonLoader.vue";
export default defineComponent({
    name: "DriveGalleryGrid",
    components: { GalleryViewSkeletonLoader: GalleryViewSkeletonLoader, GalleryGridUI: GalleryGridUI },
    props: {
        limit: {
            type: Number,
            required: true,
        },
        totalCount: {
            type: Number,
            required: true,
        },
        endpoint: {
            type: String,
            required: true,
        },
        viewContext: {
            type: Object,
        },
    },
    setup: function (props) {
        var _this = this;
        var allLoadedItems = computed(function () {
            return store.getters["drive/currentContents"];
        });
        var loadedCount = computed(function () {
            return allLoadedItems.value.length;
        });
        var loadedItems = computed(function () {
            return allLoadedItems.value.filter(function (item) { return item.type === "Photo"; });
        });
        var loadMore = function () { return __awaiter(_this, void 0, void 0, function () {
            var endpointPaginated, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadingCount.value =
                            props.limit <= props.totalCount - loadedCount.value
                                ? props.limit
                                : props.totalCount - loadedCount.value;
                        endpointPaginated = "".concat(props.endpoint, "&limit=").concat(props.limit, "&offset=").concat(loadedCount.value);
                        return [4 /*yield*/, axios.get(endpointPaginated)];
                    case 1:
                        data = (_a.sent()).data;
                        store.commit("drive/updateInodes", data.results);
                        loadingCount.value = 0;
                        return [2 /*return*/];
                }
            });
        }); };
        var loadingCount = ref(0);
        var lightboxService = useLightbox();
        var fetchPhoto = function (index) { return __awaiter(_this, void 0, void 0, function () {
            var item;
            return __generator(this, function (_a) {
                item = loadedItems.value[index];
                return [2 /*return*/, {
                        src: item.original.url,
                        width: item.original.width,
                        height: item.original.height,
                        authorName: getContextName(props.viewContext, item.uploaded_by),
                    }];
            });
        }); };
        var onImageClick = function (startIndex) {
            console.log("loeadedItems", loadedItems.value);
            lightboxService.openPhotoLightbox(startIndex, props.totalCount, fetchPhoto);
        };
        return {
            loadedItems: loadedItems,
            loadingCount: loadingCount,
            loadMore: loadMore,
            onImageClick: onImageClick,
        };
    },
});
