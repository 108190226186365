import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { computed, defineComponent, inject, ref } from "vue";
import "features/gallery/thumbnail/GalleryThumbnail.vue";
import GalleryViewSkeletonLoader from "features/gallery/view/GalleryViewSkeletonLoader.vue";
import "features/gallery/view/GalleryView.vue";
import Sortable from "sortablejs";
import { mdiDrag } from "@mdi/js";
import "features/gallery/lightbox/lightbox.service";
import "features/gallery/gallery.types";
import ServicedGalleryThumbnail from "features/gallery/service/ServicedGalleryThumbnail.vue";
import "features/gallery/service/gallery.service";
import { useIsBreakpointInNames } from "features/common/vuetify/useVuetify";
import "features/events/highlights/lightbox/event-lightbox.service";
import "features/posts/gallery/lightbox/post-lightbox.service";
import GalleryGridUI from "app/ui-primitives/gallery/GalleryGridUI.vue";
export default defineComponent({
    name: "GalleryViewGrid",
    components: {
        GalleryGridUI: GalleryGridUI,
        ServicedGalleryThumbnail: ServicedGalleryThumbnail,
        GalleryViewSkeletonLoader: GalleryViewSkeletonLoader,
    },
    props: {
        compact: {
            type: Boolean,
            default: false,
        },
        loadGalleryMedia: {
            type: Function,
        },
        totalGalleryMedia: {
            type: Number,
            default: 0,
        },
        pageSize: {
            type: Number,
            default: 12,
        },
        sortable: {
            type: Boolean,
            default: false,
        },
        optimisticUpdate: {
            type: Boolean,
            default: false,
        },
        isHighlightGallery: {
            type: Boolean,
            default: true,
        },
    },
    setup: function (props, context) {
        var _this = this;
        var loadedGalleryMedia = ref([]);
        var offset = ref(0);
        var isLoading = ref(false);
        var optimisticMediaCount = ref(0);
        var totalGalleryMediaCount = computed(function () {
            return props.totalGalleryMedia + optimisticMediaCount.value;
        });
        if (props.optimisticUpdate) {
            var galleryService = inject("GalleryService");
            galleryService.registerRemoveListener(function (galleryId) {
                loadedGalleryMedia.value = loadedGalleryMedia.value.filter(function (medium) { return medium.id != galleryId; });
                optimisticMediaCount.value -= 1;
            });
        }
        var itemsLeft = computed(function () {
            return totalGalleryMediaCount.value - loadedGalleryMedia.value.length;
        });
        var nextAmountOfItems = computed(function () {
            var nextAmount = Math.min(itemsLeft.value, props.pageSize);
            if (nextAmount >= 0) {
                return nextAmount;
            }
            else {
                return 0;
            }
        });
        var _loadMore = function () { return __awaiter(_this, void 0, void 0, function () {
            var newGalleryMedia;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, props.loadGalleryMedia(offset.value, nextAmountOfItems.value)];
                    case 1:
                        newGalleryMedia = _a.sent();
                        loadedGalleryMedia.value = __spreadArray(__spreadArray([], loadedGalleryMedia.value, true), newGalleryMedia, true);
                        offset.value += newGalleryMedia.length;
                        return [2 /*return*/];
                }
            });
        }); };
        var loadMore = function () { return __awaiter(_this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isLoading.value == true || itemsLeft.value == 0) {
                            return [2 /*return*/];
                        }
                        isLoading.value = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, _loadMore()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 4:
                        isLoading.value = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var itemSelected = function (galleryMedia, index) {
            if (isDragging.value)
                return;
            context.emit("mediumSelected", __assign(__assign({}, galleryMedia), { index: index }));
        };
        // init first load
        if (props.loadGalleryMedia) {
            loadMore().then(function () {
                itemSelected(loadedGalleryMedia.value[0], 0);
            });
        }
        var isDragging = ref(false);
        var isSmallerDevice = useIsBreakpointInNames(["xs", "sm", "md"]);
        var initSortIfEnabled = function (mountedGrid) {
            if (!props.sortable) {
                return;
            }
            new Sortable(mountedGrid, {
                animation: 350,
                sort: true,
                filter: ".ignore-sort",
                handle: isSmallerDevice.value ? undefined : ".gallery-drag-handle",
                chosenClass: "gallery-drag-chosen",
                delay: 300,
                delayOnTouchOnly: true,
                onStart: function () {
                    isDragging.value = true;
                },
                onEnd: function (event) {
                    isDragging.value = false;
                    var galleryMedium = loadedGalleryMedia.value[event.oldIndex];
                    var _id = props.isHighlightGallery
                        ? galleryMedium.highlight_id
                        : galleryMedium.id;
                    context.emit("reorder", {
                        id: _id,
                        index: event.newIndex,
                    });
                    loadedGalleryMedia.value = moveElement(loadedGalleryMedia.value, event.oldIndex, event.newIndex);
                },
            });
        };
        var thumbnailDragHandle = {
            icon: mdiDrag,
            action: function () {
                //pass
            },
            class: "gallery-drag-handle",
        };
        var lightBoxService = inject("lightboxService");
        var eventLightboxService = inject("EventLightboxService", null);
        var postLightboxService = inject("PostLightboxService", null);
        /**
         *  TODO: This shouldn't be here, the logic for opening the lightbox should be in the GalleryView Component
         *  Why?
         *  - It hides the side effects of opening the lightbox from the GalleryView component
         *  - The openLightBox functionality can be refactored to not be so nested (feels like a matryoshka doll rn)
         *  - There are too many different injections and services, EventLightboxService, PostLightboxService,
         *    LightboxService, should be simplified to a single service. At the top-level component (the one that uses the GalleryView), the
         *    service should be provided with the right logic (i.e event-logic, post-logic or default logic) and the GalleryView should
         *    just inject the service and call openLightbox, trusting that the right service is injected.
         */
        var openLightBox = function (galleryMedium, index) {
            if (postLightboxService) {
                postLightboxService.openLightbox(index);
                return;
            }
            if (eventLightboxService) {
                eventLightboxService.openLightbox(index);
                return;
            }
            lightBoxService.openLightbox(galleryMedium.uploaded_file.original.url);
        };
        var loadingCount = computed(function () {
            return isLoading.value ? nextAmountOfItems.value : 0;
        });
        return {
            itemSelected: itemSelected,
            loadedGalleryMedia: loadedGalleryMedia,
            nextAmountOfItems: nextAmountOfItems,
            loadMore: loadMore,
            thumbnailDragHandle: thumbnailDragHandle,
            openLightBox: openLightBox,
            loadingCount: loadingCount,
            totalGalleryMediaCount: totalGalleryMediaCount,
            initSortIfEnabled: initSortIfEnabled,
        };
    },
});
function moveElement(arr, oldIndex, newIndex) {
    if (oldIndex < 0 ||
        oldIndex >= arr.length ||
        newIndex < 0 ||
        newIndex >= arr.length) {
        throw new Error("Index out of bounds");
    }
    var element = arr[oldIndex];
    var newArr = __spreadArray([], arr, true);
    newArr.splice(oldIndex, 1);
    newArr.splice(newIndex, 0, element);
    return newArr;
}
