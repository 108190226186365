import { __awaiter, __generator } from "tslib";
import { computed, inject, provide, ref } from "vue";
import "suus-api";
import { digestApi, eventsApi } from "store/api";
import { EVENT_STATUS } from "constants/constants";
import store from "store";
import { FeatureFunctions } from "app/modules/feature.functions";
var EventFunctions = FeatureFunctions.EventFunctions;
var parseApiResponse = function (response) {
    return response.data.own_status;
};
function OwnUserAttendance(eventId) {
    var _this = this;
    var ownUserId = ref(null);
    var ownUserStatusLoading = ref(false);
    var ownUserStatusError = ref(null);
    var eventAttendStatus = ref(EVENT_STATUS.REGISTER);
    var ownUserAttends = ref(false);
    var ownUserGuestsCount = ref(0);
    var _ownUserStatus = ref(null);
    var ownUserName = computed(function () { var _a; return ((_a = _ownUserStatus.value) === null || _a === void 0 ? void 0 : _a.name) || ""; });
    var _setOwnUserStatus = function (status) {
        var _a;
        ownUserAttends.value = status.type === "Attend";
        ownUserId.value = status.id;
        ownUserGuestsCount.value = (_a = status.guests) !== null && _a !== void 0 ? _a : 0;
        _ownUserStatus.value = status;
    };
    var updateOwnUserStatus = function (fetchOwnUserStatus) { return __awaiter(_this, void 0, void 0, function () {
        var status, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ownUserStatusLoading.value = true;
                    ownUserStatusError.value = null;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, fetchOwnUserStatus()];
                case 2:
                    status = _a.sent();
                    _setOwnUserStatus(status);
                    checkAttendanceStatus(status);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    ownUserStatusError.value =
                        "An error occurred while fetching the current user's status.";
                    console.error("An error occurred while fetching the current user's status.", error_1);
                    return [3 /*break*/, 5];
                case 4:
                    ownUserStatusLoading.value = false;
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var initializeOwnUserStatus = function () { return __awaiter(_this, void 0, void 0, function () {
        var fetchOwnUserStatus;
        return __generator(this, function (_a) {
            fetchOwnUserStatus = function () {
                return eventsApi.getOwnStatus(eventId).then(parseApiResponse);
            };
            return [2 /*return*/, updateOwnUserStatus(fetchOwnUserStatus)];
        });
    }); };
    var initializeOwnUserStatusFromDigest = function () { return __awaiter(_this, void 0, void 0, function () {
        var fetchOwnUserStatus;
        return __generator(this, function (_a) {
            fetchOwnUserStatus = function () {
                return digestApi.getDigestEventOwnStatus(eventId).then(parseApiResponse);
            };
            return [2 /*return*/, updateOwnUserStatus(fetchOwnUserStatus)];
        });
    }); };
    /**
     * Used for checking the attendance status of the user. If no parameter is provided, it will use the stored value.
     * @param ownUserStatusParam
     */
    var checkAttendanceStatus = function (ownUserStatusParam) {
        var ownUserStatus = ownUserStatusParam || _ownUserStatus.value; // Prefer the parameter, else use the stored value
        var currentEventFromStore = store.getters["event/currentEvent"];
        eventAttendStatus.value = EventFunctions.determineEventAttendanceStatus(currentEventFromStore, ownUserStatus);
    };
    /**
     *  Used for attending or declining an event.
     */
    var updateAttendance = function (sendAttendanceResponse) { return __awaiter(_this, void 0, void 0, function () {
        var newUserStatus, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ownUserStatusLoading.value = true;
                    ownUserStatusError.value = null;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, sendAttendanceResponse()];
                case 2:
                    newUserStatus = _a.sent();
                    _setOwnUserStatus(newUserStatus);
                    checkAttendanceStatus(newUserStatus);
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    ownUserStatusError.value = "An error occurred while attending the event.";
                    console.error("An error occurred while attending the event.", error_2);
                    return [3 /*break*/, 5];
                case 4:
                    ownUserStatusLoading.value = false;
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var attend = function (guestsCount) {
        if (guestsCount === void 0) { guestsCount = 0; }
        var sendAttendResponse = function () {
            return eventsApi
                .createAttend(eventId, {
                guests_count: guestsCount,
            })
                .then(parseApiResponse);
        };
        return updateAttendance(sendAttendResponse);
    };
    var attendFromDigest = function (guestsCount) {
        if (guestsCount === void 0) { guestsCount = 0; }
        var sendAttendResponse = function () {
            return digestApi
                .createDigestEventAttend(eventId, guestsCount)
                .then(parseApiResponse);
        };
        return updateAttendance(sendAttendResponse);
    };
    var decline = function () {
        var sendDeclineResponse = function () {
            return eventsApi.createDecline(eventId).then(parseApiResponse);
        };
        return updateAttendance(sendDeclineResponse);
    };
    var declineFromDigest = function () {
        var sendDeclineResponse = function () {
            return digestApi.createDigestEventDecline(eventId).then(parseApiResponse);
        };
        return updateAttendance(sendDeclineResponse);
    };
    var setEventAttendStatusDirectly = function (eventStatus) {
        eventAttendStatus.value = eventStatus;
    };
    return {
        ownUserId: ownUserId,
        ownUserStatusLoading: ownUserStatusLoading,
        ownUserStatusError: ownUserStatusError,
        ownUserAttends: ownUserAttends,
        ownUserGuestsCount: ownUserGuestsCount,
        ownUserName: ownUserName,
        eventAttendStatus: eventAttendStatus,
        attend: attend,
        attendFromDigest: attendFromDigest,
        decline: decline,
        declineFromDigest: declineFromDigest,
        checkAttendanceStatus: checkAttendanceStatus,
        initializeOwnUserStatus: initializeOwnUserStatus,
        initializeOwnUserStatusFromDigest: initializeOwnUserStatusFromDigest,
        setEventAttendStatusDirectly: setEventAttendStatusDirectly,
    };
}
/**
 * This should be called in the top-level component, where the Context should be set up.
 * This probably shouldn't be called multiple times, as it will create multiple contexts.
 */
export function provideOwnUserAttendanceContext(eventId) {
    var concern = OwnUserAttendance(eventId);
    provide("OwnUserAttendance", concern);
    return concern;
}
/**
 * This should be called in any component that needs to access the Own User Attendance Context.
 * It expects that the context has been set up by `provideOwnUserAttendanceContext`.
 */
export function useOwnUserAttendanceContext() {
    var concern = inject("OwnUserAttendance", null);
    if (concern === null) {
        throw new Error("OwnUserAttendance was not found in 'useOwnUserAttendanceContext'. Did you forget to provide a context with 'provideOwnUserAttendanceContext'?");
    }
    return concern;
}
