import { __awaiter, __generator } from "tslib";
import { defineComponent, ref, watch } from "vue";
import "suus-api";
import CommentForm from "components/Comment/CommentForm.vue";
import "concerns/useContextName";
import CommentList from "components/Comment/CommentList.vue";
import useUpdatePostComment from "concerns/useUpdatePostComment";
import useDeleteComment from "concerns/useDeleteComment";
import { postsApi } from "store/api";
export default defineComponent({
    name: "CommentSection",
    components: { CommentList: CommentList, CommentForm: CommentForm },
    props: {
        initialComments: { type: Array, default: function () { return []; } },
        commentFormOpen: { type: Boolean, default: false },
        canCreateComment: { type: Boolean, default: false },
        viewContext: { type: Object },
        postId: { type: Number, required: true },
    },
    setup: function (props, context) {
        var commentListComponent = ref(null);
        var commentText = ref("");
        var commentList = ref(props.initialComments);
        var totalLoaded = ref(0);
        var updateTotalLoaded = function (newVal) {
            totalLoaded.value = newVal;
        };
        var closeCommentForm = function () {
            context.emit("changeCommentFormVisibility", false);
        };
        var openCommentForm = function () {
            context.emit("changeCommentFormVisibility", true);
        };
        function createComment(text) {
            var _a;
            return __awaiter(this, void 0, Promise, function () {
                var createdComment, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            closeCommentForm();
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, postsApi
                                    .createPostComment(props.postId.toString(), {
                                    text: text,
                                })
                                    .then(function (res) { return res.data; })];
                        case 2:
                            createdComment = _b.sent();
                            (_a = commentListComponent.value) === null || _a === void 0 ? void 0 : _a.add(createdComment);
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _b.sent();
                            openCommentForm();
                            commentText.value = text;
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
        function updateComment(comment) {
            return new Promise(function (resolve, reject) {
                var _a = useUpdatePostComment(comment), updatedComment = _a.updatedComment, error = _a.error;
                watch(updatedComment, function (newVal) {
                    resolve(newVal);
                });
                watch(error, function (err) {
                    reject(err);
                });
            });
        }
        function deleteComment(comment) {
            return new Promise(function (resolve, reject) {
                var _a = useDeleteComment(comment.id.toString()), isSuccess = _a.isSuccess, error = _a.error;
                watch(isSuccess, function (err) {
                    resolve(comment.id);
                });
                watch(error, function (err) {
                    reject(err);
                });
            });
        }
        return {
            commentText: commentText,
            createComment: createComment,
            commentList: commentList,
            updateComment: updateComment,
            deleteComment: deleteComment,
            closeCommentForm: closeCommentForm,
            totalLoaded: totalLoaded,
            updateTotalLoaded: updateTotalLoaded,
            commentListComponent: commentListComponent,
        };
    },
});
