import "suus-api";
import moment from "moment";
import { EVENT_STATUS } from "constants/constants";
/**
 * Functions that do some business logic on the Suus-API Event objects.
 * This is a static class, so you can use it without instantiating it.
 * The use of static class is meant to improve the discoverability of the functions.
 */
var EventFunctions = /** @class */ (function () {
    function EventFunctions() {
    }
    EventFunctions.hasEventPassed = function (event) {
        var daysSinceEnd = moment()
            .utc()
            .diff(event.end_at || event.start_at, "days");
        return daysSinceEnd > 0;
    };
    EventFunctions.isEventAttendableOnline = function (event) {
        var daysPassedSinceStart = moment().utc().diff(event.start_at, "days");
        // @Stephan Biastoch: I copied the logic from the store. daysPassedSinceStart >= -1 seems wrong.
        // Shouldn't it be daysPassedSinceStart < 0 or daysPassedSinceStart <= -1?
        return event.online && daysPassedSinceStart >= -1;
    };
    /**
     * Determines the status of the event attendance for a given event and attend object.
     * If the event or attend object is missing, the function will return the default status REGISTER.
     *
     * @param event
     * @param attend
     * @returns EVENT_STATUS
     */
    EventFunctions.determineEventAttendanceStatus = function (event, attend) {
        // DEFAULT CASE if data is incomplete
        if (!attend || !event) {
            return EVENT_STATUS.REGISTER;
        }
        // EVENT_PASSED
        var isEventPassed = EventFunctions.hasEventPassed(event);
        if (isEventPassed) {
            return EVENT_STATUS.EVENT_PASSED;
        }
        // DEREGISTERED
        var hasUserDeclined = attend.type === "Decline";
        if (hasUserDeclined) {
            return EVENT_STATUS.DEREGISTERED;
        }
        var hasUserAttended = attend.type === "Attend";
        // ATTEND_ONLINE_NOW
        var isEventAttendableOnline = EventFunctions.isEventAttendableOnline(event);
        if (hasUserAttended && isEventAttendableOnline) {
            return EVENT_STATUS.ATTEND_ONLINE_NOW;
        }
        // REGISTRATION_FINISHED
        var isRegistrationFinished = !event.allow_guests || attend.finished_registration;
        if (hasUserAttended && isRegistrationFinished) {
            return EVENT_STATUS.FINISHED_REGISTRATION;
        }
        // REGISTERED
        if (hasUserAttended) {
            return EVENT_STATUS.REGISTERED;
        }
        // DEFAULT CASE
        return EVENT_STATUS.REGISTER;
    };
    return EventFunctions;
}());
export { EventFunctions };
