import { __assign, __awaiter, __generator } from "tslib";
import { EVENT_STATUS } from "constants/constants";
import { createNamespacedHelpers } from "vuex";
var mapState = createNamespacedHelpers("event").mapState;
import { mdiAccountCircleOutline } from "@mdi/js";
import { mdiCheck } from "@mdi/js";
import { mdiClose } from "@mdi/js";
import ConfettiFlag from "../graphics/ConfettiFlag.vue";
import Subtitle from "../UI/Subtitle.vue";
import Header from "../UI/Header.vue";
import Button from "../UI/Button.vue";
import TextButton from "../UI/TextButton.vue";
import TextBody from "../UI/TextBody.vue";
import Hyperlink from "../UI/Hyperlink.vue";
import StatusIcon from "../UI/StatusIcon.vue";
import SamePageLink from "../UI/SamePageLink.vue";
import AvatarPlaceholder from "../UI/AvatarPlaceholder.vue";
import GuestModal from "./GuestModal.vue";
import { computed, defineComponent } from "vue";
import "suus-api";
import "vue-i18n";
import EventPassedContent from "components/Event/event-card/EventPassedContent.vue";
import { useOwnUserAttendanceContext } from "app/modules/events/use-own-user-attendance-context.concern";
import { useRSVPContext } from "app/modules/events/rsvp-context/use-rsvp-context.concern";
export default defineComponent({
    name: "EventCard",
    components: {
        EventPassedContent: EventPassedContent,
        ConfettiFlag: ConfettiFlag,
        Subtitle: Subtitle,
        Header: Header,
        TextButton: TextButton,
        TextBody: TextBody,
        Hyperlink: Hyperlink,
        StatusIcon: StatusIcon,
        SamePageLink: SamePageLink,
        AvatarPlaceholder: AvatarPlaceholder,
        Button: Button,
        GuestModal: GuestModal,
    },
    data: function () {
        return {
            showModal: false,
            EVENT_STATUS: EVENT_STATUS,
            userIcon: mdiAccountCircleOutline,
        };
    },
    methods: {
        deregister: function () {
            this.decline();
        },
        handleNoGuests: function () {
            this.attend(0);
            this.setEventAttendStatusDirectly(EVENT_STATUS.FINISHED_REGISTRATION);
        },
        primaryClick: function () {
            var _a;
            if (this.cardStatus === EVENT_STATUS.REGISTER) {
                this.attend();
            }
            else if (this.cardStatus === EVENT_STATUS.REGISTERED) {
                this.showModal = true;
            }
            else if (this.cardStatus === EVENT_STATUS.DEREGISTERED) {
                //Abgemeldet --> Anmelden
                this.attend();
            }
            else if (this.cardStatus === EVENT_STATUS.FINISHED_REGISTRATION) {
                this.scrollToDescription();
            }
            else if (this.cardStatus === EVENT_STATUS.ATTEND_ONLINE_NOW) {
                window.open((_a = this.eventCallUrl) !== null && _a !== void 0 ? _a : undefined, "_blank");
            }
        },
        secondaryClick: function () {
            if (this.cardStatus === EVENT_STATUS.REGISTER) {
                //Abmelden
                this.setEventAttendStatusDirectly(EVENT_STATUS.DEREGISTERED);
                this.decline();
            }
            else if (this.cardStatus === EVENT_STATUS.ATTEND_ONLINE_NOW) {
                this.scrollToAttendeePanel();
            }
            else {
                this.handleNoGuests();
            }
        },
        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        },
        scrollToAttendeePanel: function () {
            this.$vuetify.goTo("#suus__event__attendee-list", {
                duration: 300,
                offset: 0,
                easing: "easeInOutCubic",
            });
            this.addActivePanel(1);
        },
        scrollToDescription: function () {
            this.$vuetify.goTo("#suus__event__description", {
                duration: 300,
                offset: 0,
                easing: "easeInOutCubic",
            });
            this.addActivePanel(0);
        },
        addActivePanel: function (panelIndex) {
            this.$store.commit("event/addActivePanel", panelIndex);
        },
    },
    computed: __assign(__assign({ declineButtonLabel: function () {
            return this.$t("declines.decline").toString();
        }, cardOptions: function () {
            return {
                register: {
                    headerText: this.$i18n
                        .t("components.event_card.card_options.register.headerText")
                        .toString(),
                    primaryBtnLabel: this.$i18n
                        .t("components.event_card.card_options.register.primaryBtnLabel")
                        .toString(),
                    secondaryBtnLabel: this.$i18n
                        .t("components.event_card.card_options.register.secondaryBtnLabel")
                        .toString(),
                },
                registered: {
                    headerText: this.$i18n
                        .t("components.event_card.card_options.registered.headerText")
                        .toString(),
                    primaryBtnLabel: this.$i18n
                        .t("components.event_card.card_options.registered.primaryBtnLabel")
                        .toString(),
                    secondaryBtnLabel: this.$i18n
                        .t("components.event_card.card_options.registered.secondaryBtnLabel")
                        .toString(),
                    icon: mdiCheck,
                },
                deregistered: {
                    headerText: this.$i18n
                        .t("components.event_card.card_options.deregistered.headerText")
                        .toString(),
                    primaryBtnLabel: this.$i18n
                        .t("components.event_card.card_options.deregistered.primaryBtnLabel")
                        .toString(),
                    icon: mdiClose,
                },
                finished_registration: {
                    headerText: this.$i18n
                        .t("components.event_card.card_options.finished_registration.headerText")
                        .toString(),
                    primaryBtnLabel: this.$i18n
                        .t("components.event_card.card_options.finished_registration.primaryBtnLabel")
                        .toString(),
                    icon: mdiCheck,
                },
                attend_online_now: {
                    headerText: this.$i18n
                        .t("components.event_card.card_options.attend_online_now.headerText")
                        .toString(),
                    primaryBtnLabel: this.$i18n
                        .t("components.event_card.card_options.attend_online_now.primaryBtnLabel")
                        .toString(),
                    secondaryBtnLabel: this.$i18n
                        .t("components.event_card.card_options.attend_online_now.secondaryBtnLabel")
                        .toString(),
                    icon: mdiCheck,
                },
                event_passed: {
                    headerText: this.$i18n
                        .t("components.event_card.card_options.event_passed.headerText")
                        .toString(),
                },
            };
        }, modalWidth: function () {
            // TODO: Refactor into mixin, also used in EventAttendeeList
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 600;
                case "sm":
                    return 800;
                case "md":
                    return 600;
                default:
                    return 800;
            }
        }, eventId: function () {
            return this.$route.params.event_id;
        }, eventCallUrl: function () {
            return this.eventData.custom_call_url;
        }, isAttending: function () {
            return (this.cardStatus === EVENT_STATUS.REGISTERED ||
                this.cardStatus === EVENT_STATUS.FINISHED_REGISTRATION);
        }, confettiFlagClasses: function () {
            return [
                "suus__grapic d-none d-sm-flex",
                "".concat((this.cardStatus === EVENT_STATUS.DEREGISTERED ||
                    this.cardStatus === EVENT_STATUS.EVENT_PASSED) &&
                    "disabled"),
            ];
        }, headerCustomClass: function () {
            return this.isAttending && !this.loading ? "mb-0" : "mb-9";
        }, showOtherAttendeesInsteadOfCoetans: function () {
            return !!(this.nonCoetans &&
                this.nonCoetansCount &&
                ((this.coetanenCount <= 0 && !this.ownUserAttends) ||
                    (this.coetanenCount === 1 && this.ownUserAttends)));
        }, noAttendeesText: function () {
            return !this.hasAttendees
                ? this.$t("components.event_card.no_attendees_text").toString()
                : null;
        }, noAttendeesDeRegisteredText: function () {
            return this.$t("components.event_card.deregistered_text", [
                this.ownUserName.length > 0 ? ", ".concat(this.ownUserName) : "",
            ]).toString();
        }, teaserAttendees: function () {
            if (!this.showOtherAttendeesInsteadOfCoetans &&
                this.coetanAttendees &&
                this.coetanAttendees.length) {
                return this.coetanAttendees.slice(0, 3);
            }
            if (this.nonCoetans && this.nonCoetansCount) {
                return this.nonCoetans.slice(0, 3);
            }
            return null;
        }, teaserAttendeesSubtitle: function () {
            if (this.ownUserIsFirstAttendee) {
                var user = this.ownUserName.length > 0 ? ", ".concat(this.ownUserName) : "";
                return this.$t("components.event_card.attendees_subtitle.user_is_first", [user]).toString();
            }
            if (this.ownUserAttends && !this.showOtherAttendeesInsteadOfCoetans) {
                return this.coetanenCount <= 2
                    ? this.$t("components.event_card.attendees_subtitle.one_coetan_attends").toString()
                    : this.$t("components.event_card.attendees_subtitle.more_coetans_attend", [this.coetanenCount - 1]).toString();
            }
            if (!this.showOtherAttendeesInsteadOfCoetans) {
                return this.coetanenCount === 1
                    ? this.$t("components.event_card.attendees_subtitle.one_coetan_attends").toString()
                    : this.$t("components.event_card.attendees_subtitle.more_coetans_attend", [this.coetanenCount]).toString();
            }
            if (this.nonCoetansCount > 0) {
                return this.nonCoetansCount === 1
                    ? this.$t("components.event_card.attendees_subtitle.one_club_member_attends").toString()
                    : this.$t("components.event_card.attendees_subtitle.more_club_members_attends", [this.nonCoetansCount]).toString();
            }
            return null;
        }, teaserAttendeesText: function () {
            if (!this.teaserAttendees)
                return "";
            var names = this.teaserAttendees.map(function (el) {
                return el.name;
            });
            var dots = names.length < 3 ? "" : "... ";
            if (names.length === 1) {
                return names.join("");
            }
            if (names.length === 2) {
                return names.join(" und ");
            }
            if (this.$vuetify.breakpoint.xs) {
                return names.join(", ").substr(0, 35) + dots;
            }
            if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) {
                return names.join(", ").substr(0, 66) + dots;
            }
            return names.join(", ") + dots;
        }, teaserOtherAttendeesSubtitle: function () {
            if (!this.showOtherAttendeesInsteadOfCoetans &&
                this.nonCoetansCount > 0) {
                return this.$t("components.event_card.other_attendees_subtitle", [
                    this.nonCoetansCount,
                ]).toString();
            }
            return null;
        }, teaserOtherGuestsSubtitle: function () {
            if (this.totalGuestsCount > 0) {
                return this.totalGuestsCount === 1
                    ? this.$t("components.event_card.other_guests_subtitle.one").toString()
                    : this.$t("components.event_card.other_guests_subtitle.more", [
                        this.totalGuestsCount,
                    ]).toString();
            }
            return null;
        }, headerText: function () {
            if (this.cardOptions[this.cardStatus].headerText) {
                return this.cardOptions[this.cardStatus].headerText || null;
            }
            return null;
        }, primaryBtnLabel: function () {
            if (this.cardOptions[this.cardStatus].primaryBtnLabel) {
                return this.cardOptions[this.cardStatus].primaryBtnLabel || null;
            }
            return null;
        }, secondaryBtnLabel: function () {
            if (this.cardOptions[this.cardStatus].secondaryBtnLabel) {
                return this.cardOptions[this.cardStatus].secondaryBtnLabel || null;
            }
            return null;
        }, btnColor: function () {
            return this.cardStatus === EVENT_STATUS.ATTEND_ONLINE_NOW
                ? "highlight"
                : "primary";
        }, showGuestsText: function () {
            return !!(this.eventData &&
                this.eventData.allow_guests &&
                this.cardStatus === EVENT_STATUS.FINISHED_REGISTRATION);
        }, guestsText: function () {
            if (this.showGuestsText) {
                if (this.ownUserGuestsCount > 0) {
                    return this.$t("components.event_card.guests_text.your_guests", [
                        this.ownUserGuestsCount,
                    ]).toString();
                }
                return this.$t("components.event_card.guests_text.no_guests").toString();
            }
            return null;
        }, icon: function () {
            if (this.cardOptions[this.cardStatus].icon) {
                return this.cardOptions[this.cardStatus].icon || null;
            }
            return null;
        }, graphicWidth: function () {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "180";
                case "sm":
                    return "100%";
                default:
                    return "250";
            }
        }, skeletonLoader: function () {
            return {
                type: "sentences, list-item-avatar-three-line, list-item",
            };
        } }, mapState({
        eventData: function (state) { return state.currentEvent.data; },
        eventLoading: function (state) { return state.currentEvent.loading; },
        allow_guests: function (state) {
            return (state.currentEvent.data &&
                state.currentEvent.data.allow_guests);
        },
    })), { totalGuestsCount: function () {
            return this.guestsCount;
        }, hasAttendees: function () {
            return this.attendsCount > 0;
        }, loading: function () {
            return (this.$store.getters["event/loading"] ||
                this.ownUserStatusLoading ||
                this.loading);
        } }),
    setup: function () {
        var _this = this;
        var _a = useOwnUserAttendanceContext(), eventAttendStatus = _a.eventAttendStatus, _attend = _a.attend, _decline = _a.decline, setEventAttendStatusDirectly = _a.setEventAttendStatusDirectly, ownUserStatusLoading = _a.ownUserStatusLoading, ownUserGuestsCount = _a.ownUserGuestsCount, ownUserAttends = _a.ownUserAttends, ownUserName = _a.ownUserName, ownUserId = _a.ownUserId;
        var _b = useRSVPContext(), reloadRSVPs = _b.reloadRSVPs, loading = _b.loading, attends = _b.attends;
        var coetanAttendees = attends.coetans, coetanenCount = attends.coetansCount, nonCoetansCount = attends.nonCoetansCount, nonCoetans = attends.nonCoetans, attendsCount = attends.attendsCount, guestsCount = attends.guestsCount;
        var attend = function (guests_count) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _attend(guests_count)];
                    case 1:
                        _a.sent();
                        reloadRSVPs();
                        return [2 /*return*/];
                }
            });
        }); };
        var decline = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _decline()];
                    case 1:
                        _a.sent();
                        reloadRSVPs();
                        return [2 /*return*/];
                }
            });
        }); };
        var ownUserIsFirstAttendee = computed(function () {
            return (ownUserAttends.value &&
                coetanAttendees.value.length > 0 &&
                coetanAttendees.value[0].personal_record_id === ownUserId.value);
        });
        return {
            cardStatus: eventAttendStatus,
            attend: attend,
            decline: decline,
            setEventAttendStatusDirectly: setEventAttendStatusDirectly,
            ownUserStatusLoading: ownUserStatusLoading,
            ownUserGuestsCount: ownUserGuestsCount,
            ownUserAttends: ownUserAttends,
            ownUserName: ownUserName,
            ownUserIsFirstAttendee: ownUserIsFirstAttendee,
            nonCoetansCount: nonCoetansCount,
            nonCoetans: nonCoetans,
            coetanAttendees: coetanAttendees,
            coetanenCount: coetanenCount,
            loading: loading,
            guestsCount: guestsCount,
            attendsCount: attendsCount,
        };
    },
});
