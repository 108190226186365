<template lang="pug">
v-expansion-panels(accordion flat tile multiple :value="activePanelsComputed").suus__event__expansion-panels
    v-expansion-panel(v-for="(item, i) in menuItems" :key="i")
        v-expansion-panel-header(:id="item.selector")
            template(v-slot:default).suus__panel-header
                header
                    div.d-flex.justify-center
                      Header(:text="item.title" :level="5" customClass="mb-0")
                      v-badge(v-if="item.newBadge" color="primary" :offset-y="8")
                        template(v-slot:badge)
                          span.new-badge Neu
                      v-icon.ml-2(v-if="item.action" @click.stop="item.action.action" color="textColor" ) {{ item.action.icon }}
                    Subtitle(v-if="item.subline" :text="item.subline" :fontSize="1" )
            template(v-slot:actions)
                v-icon(color="textColor") {{ panelIcon }}
        v-expansion-panel-content
          component(
                :is="item.component"
                :model="item.content"
                v-bind="item.props"
            )

</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapState, mapGetters } = createNamespacedHelpers("event")
import EventDescription from "./EventDescription.vue"
import EventOrganization from "./EventOrganization.vue"
import EventAttendeeList from "./EventAttendeeList.vue"
import Header from "../UI/Header.vue"
import Subtitle from "../UI/Subtitle.vue"
import { mdiChevronDown, mdiDownload } from "@mdi/js"
import EventHighlightsTab from "features/events/highlights/EventHighlightsTab.vue"
import { inject } from "vue"
import { useRSVPContext } from "app/modules/events/rsvp-context/use-rsvp-context.concern"
export default {
  name: "EventExpansionPanel",
  components: { Header, EventAttendeeList, EventOrganization, Subtitle },
  props: ["viewContext"],
  data() {
    return { panelIcon: mdiChevronDown }
  },
  computed: {
    setColor() {
      return this.$vuetify.theme.themes.light.mediumGrey
    },
    menuItems() {
      return [
        {
          title: "Beschreibung",
          component: EventDescription,
          selector: "suus__event__description",
          props: [
            {
              openEditFunction: this.openEdit,
              showEditButton: !!this.currentEvent?.links.update_url,
            },
          ],
        },
        ...(this.galleryService.highlightsCount.value > 0 ||
        this.galleryService.initialShowUploader.value
          ? [
              {
                title: "Highlights",
                component: EventHighlightsTab,
                selector: "suus__event__highlights",
                props: [
                  {
                    event: this.currentEvent,
                    viewContext: this.viewContext,
                  },
                ],
                newBadge: true,
              },
            ]
          : []),
        {
          title: this.attendeeListSubline,
          component: EventAttendeeList,
          selector: "suus__event__attendee-list",
          action: this.rsvpAction,
        },
        {
          title: "Organisation",
          component: EventOrganization,
          selector: "suus__event__organization",
        },
      ]
    },
    rsvpAction() {
      const isDownloadAvailable =
        this.currentEvent?.attends_count > 0 && this.currentEvent?.id
      return isDownloadAvailable
        ? {
            icon: mdiDownload,
            action: () => {
              this.downloadRsvp()
            },
          }
        : null
    },
    attendeeListSubline() {
      return `${this.totalAttendsCount} Anmeldungen`
    },
    activePanelsComputed() {
      const activePanels = this.activePanels
      if (
        this.galleryService.highlightsCount.value > 0 ||
        this.galleryService.initialShowUploader.value
      ) {
        return [...activePanels, 1]
      } else {
        return activePanels
      }
    },
    ...mapState(["activePanels"]),
    ...mapGetters(["currentEvent"]),
  },
  methods: {
    openEdit() {
      this.$emit("openEditAnimation")
    },
    downloadRsvp() {
      const link = document.createElement("a")
      link.href = `/api/v1/events/${this.currentEvent.id?.toString()}/rsvps.xlsx`
      link.download = "" // or set it to filename if you want
      link.style.display = "none"

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
    },
  },
  setup() {
    const galleryService = inject("GalleryService")
    const { attends } = useRSVPContext()
    const { totalAttendsCount } = attends
    return {
      galleryService,
      totalAttendsCount,
    }
  },
}
</script>

<style lang="sass" scoped>
.suus__event__expansion-panels::v-deep
    .suus__panel-header
        display: flex
        flex-direction: column
    .v-expansion-panel
        background-color: transparent !important
    .v-expansion-panel-header
        border-top: 1px solid #C4C4C4
</style>
