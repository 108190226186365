import { __assign } from "tslib";
import { defineComponent, getCurrentInstance } from "vue";
import { EVENT_STATUS } from "constants/constants";
import { createNamespacedHelpers } from "vuex";
var _a = createNamespacedHelpers("event"), mapState = _a.mapState, mapActions = _a.mapActions, mapGetters = _a.mapGetters;
import Digest from "../graphics/Digest.vue";
import Header from "../UI/Header.vue";
import ErrorHero from "../UI/ErrorHero.vue";
import AttendCard from "./AttendCard.vue";
import GuestModal from "./GuestModal.vue";
import "suus-api";
import { provideOwnUserAttendanceContext } from "app/modules/events/use-own-user-attendance-context.concern";
import store from "store";
export default defineComponent({
    name: "AttendPage",
    components: { Digest: Digest, Header: Header, AttendCard: AttendCard, GuestModal: GuestModal, ErrorHero: ErrorHero },
    computed: __assign(__assign(__assign({ titleText: function () {
            return this.$t("components.event.error.titleText").toString();
        }, line1: function () {
            return this.$t("components.event.error.line1").toString();
        }, line2: function () {
            return this.$t("components.event.error.line2").toString();
        }, btnLabel: function () {
            return this.$t("components.event.error.btnLabel").toString();
        }, loading: function () {
            return this.eventLoading || this.ownUserStatusLoading;
        }, headerTextOnAttend: function () {
            var line1 = this.$t("components.attend.headerText.line1");
            if (this.ownUserName)
                line1 = "".concat(line1, " ").concat(this.ownUserName);
            line1 = line1 + ",";
            var line2 = this.$t("components.attend.headerText.line2.REGISTER").toString();
            if (this.cardStatus === EVENT_STATUS.REGISTERED ||
                this.cardStatus === EVENT_STATUS.FINISHED_REGISTRATION) {
                line2 = this.$t("components.attend.headerText.line2.REGISTERED").toString();
            }
            if (this.cardStatus === EVENT_STATUS.DEREGISTERED) {
                line2 = this.$i18n
                    .t("components.attend.headerText.line2.REGISTER")
                    .toString();
            }
            if (this.cardStatus === EVENT_STATUS.EVENT_PASSED) {
                line2 = this.$t("components.attend.headerText.line2.EVENT_PASSED").toString();
            }
            if (this.cardStatus === EVENT_STATUS.ATTEND_ONLINE_NOW) {
                line2 = this.$t("components.attend.headerText.line2.ATTEND_ONLINE_NOW").toString();
            }
            return { line1: line1, line2: line2 };
        }, headerTextOnDecline: function () {
            var line1 = "".concat(this.$t("components.attend.headerText.line1"), " ").concat(this.ownUserName, ",");
            var line2 = this.$t("components.attend.headerText.line2.DECLINE").toString();
            if (this.cardStatus === EVENT_STATUS.DEREGISTERED) {
                line2 = this.$i18n
                    .t("components.attend.headerText.line2.DEREGISTERED")
                    .toString();
            }
            if (this.cardStatus === EVENT_STATUS.EVENT_PASSED) {
                line2 = this.$t("components.attend.headerText.line2.EVENT_PASSED").toString();
            }
            return { line1: line1, line2: line2 };
        }, headerText: function () {
            return this.isDecline ? this.headerTextOnDecline : this.headerTextOnAttend;
        }, isDecline: function () {
            return this.$route.name === "Decline";
        }, titleLevel: function () {
            return this.$vuetify.breakpoint.name === "xs" ? 4 : 1;
        }, digestWidth: function () {
            return this.$vuetify.breakpoint.name === "xs" ? "30" : "80";
        } }, mapState({
        eventData: function (state) { return state.currentEvent.data; },
        eventError: function (state) { return state.currentEvent.error; },
        eventLoading: function (state) { return state.currentEvent.loading; },
    })), mapGetters(["hasError"])), { hasErrorInEventOrOwnUserStatus: function () {
            return this.hasError || !!this.ownUserStatusError;
        } }),
    methods: __assign({ onBtnClick: function () {
            window.open("/", "_self");
        } }, mapActions(["getEventFromDigest"])),
    setup: function () {
        var _a;
        var currentInstance = getCurrentInstance();
        var eventId = (_a = currentInstance.proxy) === null || _a === void 0 ? void 0 : _a.$route.params.event_id;
        var event$ = store.dispatch("event/getEventFromDigest", eventId);
        var ownUserAttendanceConcern = provideOwnUserAttendanceContext(eventId);
        event$.then(function () {
            ownUserAttendanceConcern.initializeOwnUserStatusFromDigest();
        });
        return {
            cardStatus: ownUserAttendanceConcern.eventAttendStatus,
            ownUserStatusLoading: ownUserAttendanceConcern.ownUserStatusLoading,
            ownUserName: ownUserAttendanceConcern.ownUserName,
            ownUserStatusError: ownUserAttendanceConcern.ownUserStatusError,
        };
    },
});
