import { __awaiter, __generator, __spreadArray } from "tslib";
import { computed, ref } from "vue";
import "suus-api";
import { rsvpApi } from "store/api";
export function Attends(eventId, ownUserId, ownUserAttends, RSVP_LOAD_LIMIT) {
    /**
     *  GENERAL ATTENDS - CACHED COUNTS
     */
    if (RSVP_LOAD_LIMIT === void 0) { RSVP_LOAD_LIMIT = 12; }
    var attendsCount = ref(0);
    var guestsCount = ref(0);
    function updateCounts(_a) {
        var _attendsCount = _a.attendsCount, _guestsCount = _a.guestsCount;
        attendsCount.value = _attendsCount;
        guestsCount.value = _guestsCount;
    }
    /**
     *  GENERAL ATTENDS - OPTIMISTIC COUNTS
     */
    var expectedCounter = ref(0);
    var expectedAttendsCount = computed(function () { return attendsCount.value + expectedCounter.value; });
    var totalAttendsCount = computed(function () { return expectedAttendsCount.value + guestsCount.value; });
    function incrementExpectedAttendsCount() {
        expectedCounter.value += 1;
    }
    function _resetExpectedAttendsCount() {
        expectedCounter.value = 0;
    }
    /**
     *  COETANS
     */
    var coetans = ref([]);
    var coetansCount = computed(function () { return coetans.value.length; });
    function _filterCoetans(_attends) {
        return _attends.filter(function (rsvp) { return rsvp.is_coetan; });
    }
    /**
     *  NON_COETANS
     */
    var nonCoetans = ref([]);
    var nonCoetansCount = computed(function () { return expectedAttendsCount.value - coetansCount.value; });
    function _filterNonCoetans(_attends) {
        return _attends.filter(function (rsvp) { return !rsvp.is_coetan && rsvp.personal_record_id !== ownUserId.value; });
    }
    /**
     *  GENERAL ATTENDS - LOADING
     */
    var attends = computed(function () { return __spreadArray(__spreadArray([], coetans.value, true), nonCoetans.value, true); });
    var _loadedAttendsCount = computed(function () { return attends.value.length; });
    var _attendsOffset = computed(function () { return _loadedAttendsCount.value + expectedCounter.value; });
    var allAttendsLoaded = ref(false);
    function _setAttends(_attends) {
        coetans.value = _filterCoetans(_attends);
        nonCoetans.value = _filterNonCoetans(_attends);
    }
    function addAttends(_attends) {
        coetans.value = __spreadArray(__spreadArray([], _filterCoetans(_attends), true), coetans.value, true);
        nonCoetans.value = __spreadArray(__spreadArray([], _filterNonCoetans(_attends), true), nonCoetans.value, true);
    }
    function loadMoreAttends() {
        return __awaiter(this, void 0, void 0, function () {
            var rsvpResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (allAttendsLoaded.value)
                            return [2 /*return*/];
                        return [4 /*yield*/, rsvpApi.getRsvps(eventId, _attendsOffset.value, RSVP_LOAD_LIMIT, undefined, "attend")];
                    case 1:
                        rsvpResponse = _a.sent();
                        addAttends(rsvpResponse.data);
                        _setAllAttendsLoaded(rsvpResponse.status === 204, rsvpResponse.data.length);
                        return [2 /*return*/];
                }
            });
        });
    }
    function loadFirstAttends() {
        return __awaiter(this, void 0, void 0, function () {
            var rsvpResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, rsvpApi.getRsvps(eventId, 0, RSVP_LOAD_LIMIT, undefined, "attend")];
                    case 1:
                        rsvpResponse = _a.sent();
                        _setAttends(rsvpResponse.data);
                        _resetExpectedAttendsCount();
                        _setAllAttendsLoaded(rsvpResponse.status === 204, rsvpResponse.data.length);
                        return [2 /*return*/];
                }
            });
        });
    }
    function _setAllAttendsLoaded(definitelyAllLoaded, _newlyLoadedCount) {
        allAttendsLoaded.value =
            definitelyAllLoaded || // Either the BE says it's all loaded
                _newlyLoadedCount < RSVP_LOAD_LIMIT || // Or we loaded less than the limit
                _loadedAttendsCount.value >= expectedAttendsCount.value; // Or we loaded all the expected count
    }
    return {
        // General Attends - Cached Counts
        attendsCount: attendsCount,
        guestsCount: guestsCount,
        updateCounts: updateCounts,
        // General Attends - Expected Counts
        totalAttendsCount: totalAttendsCount,
        incrementExpectedAttendsCount: incrementExpectedAttendsCount,
        // Coetans
        coetans: coetans,
        coetansCount: coetansCount,
        // Non Coetans
        nonCoetans: nonCoetans,
        nonCoetansCount: nonCoetansCount,
        // General Attends - Attendee Loading
        allAttendsLoaded: allAttendsLoaded,
        loadFirstAttends: loadFirstAttends,
        loadMoreAttends: loadMoreAttends,
        addAttends: addAttends,
    };
}
