import { defineComponent } from "vue";
import THEME from "constants/theme";
export default defineComponent({
    name: "SingleMenuItem",
    props: {
        icon: String,
        title: String,
        clickHandler: {
            type: Function,
            required: false,
        },
        path: {
            type: String,
            required: false,
        },
    },
    setup: function (props) {
        var _a = THEME.typography, fontFamily = _a.fontFamily, fontWeightRegular = _a.fontWeightRegular, rootFontSize = _a.rootFontSize;
        var textColor = THEME.colors.light.textColor;
        var clickHandler = function () {
            var _a;
            if (props.path) {
                window.location.href = props.path;
            }
            else {
                (_a = props.clickHandler) === null || _a === void 0 ? void 0 : _a.call(props);
            }
        };
        return {
            clickHandler: clickHandler,
            listItemStyles: {
                fontFamily: fontFamily,
                fontSize: "".concat(0.9 * rootFontSize, "px"),
                fontWeight: fontWeightRegular,
                color: textColor,
            },
        };
    },
});
